import { UI } from './ui';
import { User } from './user';

export namespace Notification {
  export interface NotificationsResponse {
    results: NotificationItem[];
    count: number;
  }

  export class NotificationItem {
    id: number;
    type: NotificationType;
    group: NotificationGroup;
    text: string;
    timestamp: Date;
    users: number[];
    author: NotificationAuthor;
    users_models?: User.Profile[];
  }

  export interface NotificationAuthor {
    username: string;
  }

  export enum NotificationType {
    project_promotion = 'project_promotion',
    server_moderation = 'server_moderation',
    project_comments = 'project_comments',
    project_first_comments = 'project_first_comments',
    max_online = 'max_online',
    max_rank = 'max_rank',
    violation = 'violation',
    notification = 'notification',
    greeting = 'greeting'
  }

  export enum NotificationGroup {
    all = 'all',
    custom = 'custom',
    server_owners = 'server_owners'
  }

  export class CreateNotificationForm extends UI.Form {
    group: NotificationGroup = null;
    group_error: string = '';
    type: UI.DropdownItem = null;
    type_error: string = '';
    users: User.Profile[] = [];
    users_error: string = '';
    text: UI.FormField = new UI.FormField();

    constructor() {
      super();
    }

    isValid(): boolean {
      if (this.group === null) {
        this.group_error = 'Выберите группу';
        return false
      }
      if (this.group && this.group === NotificationGroup.custom && this.users.length === 0) {
        this.users_error = 'Выберите пользователей';
        return false
      }
      if (this.type === null) {
        this.type_error = 'Выберите тему';
        return false
      }
      if (!this.text.isValid()) return false;
      return true;
    }
  }
}