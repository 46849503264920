
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';

@Component({
  components: {
    ButtonComponent
  }
})
export default class ModalComponent extends Vue {
  @Prop({ type: String, default: '408px' }) width: string;
  @Prop({ type: Boolean, default: false }) isShown: boolean;
  
  animationEnd: boolean = false;
  isShownLocal: boolean = false;
  scrollTop: number = 0;

  toggleBodyHidden(open: boolean) {
    const userAgent = navigator.userAgent;
    if (open) {
      document.body.classList.add('hidden');
      if (/iPad|iPhone|iPod/.test(userAgent)) {
        this.$nextTick(() => {
          this.scrollTop = window.pageYOffset;
          document.body.classList.add('ios-fix');
        });
      }
    } else {
      if (/iPad|iPhone|iPod/.test(userAgent) && document.body.className.includes('ios-fix')) {
        window.scrollTo(0, this.scrollTop);
        document.body.classList.remove('ios-fix');
      }
      document.body.classList.remove('hidden');
    }
  }

  afterEnter() { this.animationEnd = true; }
  beforeLeave() { this.animationEnd = false; }
  close() { if (this.animationEnd) this.$emit('close'); }

  @Watch('isShown') async onIsShownChanged(val: boolean) {
    await this.toggleBodyHidden(val);
    this.$nextTick(() => { this.isShownLocal = val; })
  }

  mounted() { this.isShownLocal = this.isShown; }
}
