
import { Component, Vue } from 'vue-property-decorator';
import { login } from '@/api/services/users';
import { set as setCookie } from 'es-cookie';
import { namespace,Action } from 'vuex-class';
const user = namespace('user');
import { User } from '@/types'
import InputComponent from '@/components/InputComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

@Component({
  metaInfo: {
    title: 'Авторизация'
  },
  components: {
    InputComponent,
    ButtonComponent,
    SpinnerComponent
  }
})
export default class AuthPage extends Vue {
  @user.State token: string;
  @user.Mutation setToken;
  @user.Action fetchUser;
  @user.Action logout;
  @Action init;

  isPasswordHidden: boolean = true;
  signinForm: User.SignInForm = new User.SignInForm();

  async onSubmit() {
    this.signinForm.error = '';
    this.signinForm.isLoading = true;

    try {
      if (!this.signinForm.isValid()) return

      let FormData = require('form-data');
      let formdata = new FormData();
      formdata.append('username', this.signinForm.username.value.trim());
      formdata.append('password', this.signinForm.password.value);
      formdata.append('scope', 'read write');
      formdata.append('grant_type', 'password');
      formdata.append('client_id', process.env.VUE_APP_CLIENT_ID);
      formdata.append('client_secret', process.env.VUE_APP_CLIENT_SECRET);

      const { data } = await login(formdata);
      await this.setToken(data.access_token);
      await Promise.all([
        this.fetchUser(),
        this.init()
      ])
      .catch(async (error) => await this.logout())
      this.$router.push('/');
    } catch (error) {
      if (error.data && error.data.role) this.signinForm.username.error = 'Недостаточно прав'
      else if (error.data && error.data.error && error.data.error === 'invalid_grant') this.signinForm.username.error = 'Проверьте правильность ввода логина и пароля';
      else {
        this.signinForm.username.error = 'Что-то пошло не так';
      }
    } finally {
      this.signinForm.isLoading = false;
      this.signinForm.isSubmitted = true;
    }
  }
}
