
import { Component, Vue, Prop } from 'vue-property-decorator';
import { isBetweenDates } from '../util/dateUtils';
import isSameDay from 'date-fns/isSameDay';
import DayButton from './DayButton.vue';

@Component({
  components: { DayButton }
})
export default class WeekRowComponent extends Vue {
  @Prop({ type: Date }) inDate: Date;
  @Prop({ type: Date }) outDate: Date;
  @Prop({ type: Array }) week: Date[];
  @Prop({ type: Date }) minDate: Date;
  @Prop({ type: Date }) maxDate: Date;
  @Prop({ type: Date }) selectedDate: Date;

  isSelected(day) { return (this.outDate && day > this.inDate && day < this.outDate) }

  getDisableDates(day) {
    if (day === null) return false;
    return isSameDay(day, this.minDate) ? false : !isBetweenDates(day, this.minDate, this.maxDate);
  }
};
