
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Project, Common, UI } from '@/types';
import { getServers, moderateServer } from '@/api/services/servers';
import debounce from 'lodash/debounce';
import { errorHandler } from '@/utils';
import SearchComponent from '@/components/SearchComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ReportModalComponent from '@/components/ReportModalComponent.vue';
import TextModalComponent from '@/components/TextModalComponent.vue';
import DropdownComponent from '@/components/DropdownComponent.vue';

@Component({
  metaInfo: {
    title: 'Модерация серверов'
  },
  components: {
    SearchComponent,
    SpinnerComponent,
    ButtonComponent,
    ReportModalComponent,
    TextModalComponent,
    DropdownComponent
  }
})
export default class ServersPage extends Vue {
  isPageLoading: boolean = true;
  query: string = this.$route.query && this.$route.query.q ? String(this.$route.query.q) : '';
  statesList: any[] = [
    { name: 'Все', value: null },
    { name: 'Новый сервер', value: Project.ServerState.NEW },
    { name: 'Подтвержден', value: Project.ServerState.OWNER_VERIFIED },
    { name: 'На модерации', value: Project.ServerState.MODERATION },
    { name: 'Отклонен', value: Project.ServerState.DECLINED },
    { name: 'Опубликован', value: Project.ServerState.ACTIVE },
    { name: 'Заблокирован', value: Project.ServerState.BANNED }
  ];
  state: any = this.$route.query && this.$route.query.st ? this.statesList.find(it => it.value === this.$route.query.st) : this.statesList[0];
  servers: Project.Server[] = [];
  serversCount: number = 0;
  isReportModalShown: boolean = false;
  isAcceptModalShown: boolean = false;
  banForm: Common.BanForm = new Common.BanForm;
  unbanForm: UI.Form = new UI.Form;
  selectedServer: Project.Server = null;
  isLoadingMore: boolean = false;
  onQueryChangeDebounced: Function = debounce(this.onQueryChange, 350);

  reasonsCustom: Common.BanReason[] = [
    Common.BanReason.WRONG_CATEGORIES,
    Common.BanReason.FRAUD,
    Common.BanReason.SPAM,
    Common.BanReason.OTHER
  ]

  @Watch('query') queryWatcher(value: string) {
    this.isPageLoading = true;
    this.onQueryChangeDebounced();
  }

  @Watch('state') stateWatcher(value: Project.ProjectState) {
    this.isPageLoading = true;
    this.onQueryChange();
  }

  async onQueryChange() {
    try {
      this.$router.push({ query: { q: this.query ? this.query : undefined, st: this.state && this.state.value ? this.state.value : undefined } })
      const serversList = await getServers(0, 10, this.query, this.state.value);
      this.servers = serversList.data.results;
      this.serversCount = serversList.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }

  showReportModal(server: Project.Server) {
    this.selectedServer = server;
    this.banForm = new Common.BanForm();
    this.isReportModalShown = true;
  }
  showAcceptModal(server: Project.Server) {
    this.selectedServer = server;
    this.unbanForm = new UI.Form();
    this.isAcceptModalShown = true;
  } 
  closeModals() {
    this.isReportModalShown = false;
    this.isAcceptModalShown = false;

    this.$nextTick(() => {
      this.selectedServer = null;
    });
  }

  async accept() {
    this.unbanForm.isLoading = true;
    try {
      const resp = await moderateServer(this.selectedServer.id, this.selectedServer.state === Project.ServerState.BANNED ? Project.ModerationAction.UNBAN : Project.ModerationAction.APPROVE);
      const index = this.servers.findIndex(it => it.id === this.selectedServer.id);
      if (index >= 0) this.servers[index] = resp.data;
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.unbanForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.unbanForm.isLoading = true;
    }
  }

  async report() {
    this.banForm.isLoading = true;
    this.banForm.resetErrors();
    try {
      if (!this.banForm.isValid()) return;

      const resp = await moderateServer(
        this.selectedServer.id,
        this.selectedServer.state === Project.ServerState.ACTIVE ? Project.ModerationAction.BAN : Project.ModerationAction.DECLINE,
        this.banForm.ban_reason,
        this.banForm.ban_reason === Common.BanReason.OTHER ? this.banForm.ban_details.value : undefined);
      const index = this.servers.findIndex(it => it.id === this.selectedServer.id);
      if (index >= 0) this.servers[index] = resp.data;
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (it === 'reason') this.banForm.ban_reasonError = errorHandler(it, errResponse[it][0]);
        else if (it === 'details') this.banForm.ban_details.error = errorHandler(it, errResponse[it][0]);
        else if (this.banForm[it]) this.banForm[it].error = errorHandler(it, errResponse[it][0]);
        else this.banForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.banForm.isLoading = false;
    }
  }

  async loadMore() {
    this.isLoadingMore = true;
    try {
      const serversList = await getServers(this.servers.length, 10, this.query, this.state.value);
      this.servers.push(...serversList.data.results);
      this.serversCount = serversList.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoadingMore = false;
    }
  }
  
  async mounted() {
    this.isPageLoading = true;
    try {
      const serversList = await getServers(0, 10, this.query, this.state.value);
      this.servers = serversList.data.results;
      this.serversCount = serversList.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
