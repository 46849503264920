export namespace UI {
  export class FormField {
    value: string = '';
    error: string = '';

    constructor(value?: string) {
      this.value = value || '';
    }

    isValid(): boolean {
      if (!this.value) {
        this.error = 'Поле пустое';
        return false
      }

      this.error = '';
      return true
    }
    
    isPasswordValid(): boolean {
      if (!this.value) {
        this.error = 'Поле пустое';
        return false
      }
      if (this.value.length < 8) {
        this.error = 'Минимум 8 символов'
        return false
      }
      
      this.error = ''
      return true
    }

    isEmailValid(): boolean {
      if (!this.value) {
        this.error = 'Поле пустое';
        return false
      }

      const tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
      const valid = tester.test(this.value);
      const parts = this.value.split('@');
      const domainParts = parts[1] ? parts[1].split('.') : [];
      if (this.value.length > 254 ||
        !valid ||
        parts[0].length > 64 ||
        domainParts.some((part) => { return part.length > 63; })) {
        this.error = 'Неверный email';
        return false;
      }
      
      this.error = ''
      return true
    }

    reset() {
      this.value = '';
      this.error = '';
    }
  }

  export class Form {
    error: string = '';
    isSubmitted: boolean = false;
    isLoading: boolean = false;

    constructor() {}

    resetForm() {
      this.error = '';
      this.isSubmitted = false;
    }
  }

  export interface DropdownItem {
    value: any;
    name: string;
  }

  export class ImageUpload {
    path: string;
    file: File;
    filename: string;
    error: string = '';
    id?: number;

    constructor(path?: string, file?: File, filename?: string, id?: number) {
      this.path = path ? path : '';
      this.file = file ? file : null;
      this.filename = filename ? filename : '';
      if (id != undefined && id != null) this.id = id;
    }
  }
}

export namespace Common {
  export enum BanReason {
    WRONG_CATEGORIES = 'wrong_categories',
    FRAUD = 'fraud',
    SPAM = 'spam',
    OBSCENE = 'obscene',
    OTHER = 'other'
  }

  export class BanForm extends UI.Form {
    ban_reason: BanReason = null;
    ban_reasonError: string = '';
    ban_details: UI.FormField = new UI.FormField();

    constructor() {
      super();
    }

    isValid(): boolean {
      if (this.ban_reason === null) {
        this.ban_reasonError = 'Укажите причину';
        return false
      }
      if (this.ban_reason === Common.BanReason.OTHER && this.ban_details.value.length === 0) {
        this.ban_details.error = 'Опишите причину'
        return false;
      }
      return true;
    }

    resetErrors() {
      this.ban_reasonError = '';
      this.ban_details.error = '';
      this.error = '';
    }
  }

  export class UnbanForm extends UI.Form {
    selected: any = null;

    constructor() {
      super();
    }
  }

  export class RemoveForm extends UI.Form {
    constructor() {
      super();
    }
  }
}