
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getUserLogs } from '@/api/services/users';
import { User, Common } from '@/types';
import { errorHandler } from '@/utils';

@Component
export default class UserHistoryPage extends Vue {
  @Prop({ type: Object }) user: User.Profile;

  isPageLoading: boolean = true;
  logList: User.UserLogItem[] = [];
  logsCount: number = 0;
  error: string = '';
  isLoadingMore: boolean = false;

  getActionClass(action: User.UserModerationAction) {
    switch (action) {
      case User.UserModerationAction.UNBAN: return 'green--text';
      case User.UserModerationAction.BAN: return 'red--text';
      default: return 'black--text';
    }
  }

  getActionText(action: User.UserModerationAction) {
    switch (action) {
      case User.UserModerationAction.UNBAN: return 'Разблокирован';
      case User.UserModerationAction.BAN: return 'Заблокирован';
      default: return '';
    }
  }

  getBanText(reason: Common.BanReason) {
    switch (reason) {
      case Common.BanReason.FRAUD: return 'Мошенничество';
      case Common.BanReason.WRONG_CATEGORIES: return 'Неверные категории';
      case Common.BanReason.SPAM: return 'Спам';
      case Common.BanReason.OBSCENE: return 'Нецензурная лексика';
      case Common.BanReason.OTHER: return 'Другое';
      default: return ''
    }
  }

  async mounted() {
    this.isPageLoading = true;
    try {
      const resp = await getUserLogs(this.user.id, 0, 10);
      this.logList = resp.data.results;
      this.logsCount = resp.data.count;
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.error = errorHandler(it, errResponse[it][0])
        console.log(errResponse)
      })
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
