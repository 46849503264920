
import { Component, Vue, Prop } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';

@Component({
  components: {
    ButtonComponent
  }
})
export default class ClipboardCopyComponent extends Vue {
  @Prop({ type: String }) minWidth: string;

  isTooltipShown: boolean = false;
  timeout: any = null;
  
  copy() {
    clearTimeout(this.timeout)
    const copyText = (this.$refs.copyText as HTMLElement).innerText;
    const el = document.createElement('textarea');
    el.value = copyText;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    el.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(el);
    this.isTooltipShown = true;
    this.timeout = setTimeout(() => {
      this.isTooltipShown = false;
    }, 1500);
  }
}
