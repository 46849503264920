
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { User } from '@/types';
import { getUsersList } from '@/api/services/users';
import debounce from 'lodash/debounce';
import ButtonComponent from '@/components/ButtonComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

@Component({
  components: {
    ButtonComponent,
    SpinnerComponent
  }
})
export default class UserSelectMultipleDropdownComponent extends Vue {
  @Prop({ type: Array, default: [] }) value: User.Profile[];

  query: string = '';
  isDropdownOpen: boolean = false;
  isLoading: boolean = true;
  usersList: User.Profile[] = [];
  onQueryChangeDebounced: Function = debounce(this.onQueryChange, 350);

  get filteredList() {
    return this.value && this.value.length > 0 ? this.usersList.filter(it => !this.value.find(y => y.id === it.id)) : this.usersList;
  }
  
  @Watch('query') queryWatcher(value: string) {
    this.isLoading = true;
    this.onQueryChangeDebounced();
  }

  async onQueryChange() {
    try {
      const usersList = await getUsersList(0, 20, this.query);
      this.usersList = usersList.data.results;
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false;
    }
  }
  
  openDropdown() {
    this.isDropdownOpen = true;
    this.$nextTick(() => {
      (this.$refs.search as HTMLInputElement).focus();
    });
  }
  closeDropdown() { this.isDropdownOpen = false; }
  select(user: User.Profile) {
    this.$emit('input', [...this.value, user]);
    this.$nextTick(() => {
      (this.$refs.search as HTMLInputElement).focus();
    });
  }
  remove(index: number) {
    let arr = [...this.value];
    arr.splice(index, 1);
    this.$emit('input', arr);
  }
}
