
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { User } from '@/types';
import { getUsersList } from '@/api/services/users';
import debounce from 'lodash/debounce';
import InputComponent from '@/components/InputComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

@Component({
  components: {
    InputComponent,
    ButtonComponent,
    SpinnerComponent
  }
})
export default class UserSelectDropdownComponent extends Vue {
  @Prop({ type: Object }) value: User.Profile;
  @Prop({ type: Array, default: () => ([]) }) modersList: User.Profile[];
  @Prop({ type: String, default: 'Начните вводить' }) label: string;

  query: string = '';
  isDropdownOpen: boolean = false;
  isLoading: boolean = true;
  usersList: User.Profile[] = [];
  onQueryChangeDebounced: Function = debounce(this.onQueryChange, 350);

  get filteredList() {
    return this.modersList && this.modersList.length > 0 ? this.usersList.filter(it => !this.modersList.find(y => y.id === it.id)) : this.usersList;
  }
  
  @Watch('query') queryWatcher(value: string) {
    this.isLoading = true;
    this.onQueryChangeDebounced();
  }

  async onQueryChange() {
    try {
      const usersList = await getUsersList(0, 20, this.query);
      this.usersList = usersList.data.results;
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false;
    }
  }
  
  openDropdown() {
    this.isDropdownOpen = true;
    this.$nextTick(() => {
      ((this.$refs.search as Vue).$refs.input as HTMLInputElement).focus();
    })
  }
  closeDropdown() { this.isDropdownOpen = false; }
  select(user: User.Profile) {
    this.$emit('input', user);
    this.$nextTick(() => {
      setTimeout(this.closeDropdown);
      setTimeout(() => {
        this.query = user.username;
      }, 300);
    })
  }

  mounted() {
    if (this.value) this.query = this.value.username;
  }
}
