export function errorsList(status: string) {
  switch (status) {
    case 'email': 
      return {
        'unique': 'Данный Email уже зарегистрирован'
      }
      break;

    case 'username':
      return {
        'unique': 'Данный логин уже занят'
      }
      break;
    
    case 'password':
    case 'old_password':
    case 'new_password':
      return {
        'too_common': 'Пароль слишком простой',
        'too_short': 'Пароль слишком короткий, должно быть минимум 8 символов',
        'entirely_numeric': 'Пароль не должен содержать только цифры',
        'wrong_password': 'Неверный пароль',
        'equal_to_old_password': 'Новый пароль не должен совпадать со старым'
      }
      break;

    case 'address':
      return {
        'cannot_connect': 'Адрес недоступен',
        'not_ip_nor_hostname': 'Необходимо ввести IP адрес либо доменное имя'
      }
    
    case 'launcher_link':
    case 'project_site_link':
    case 'video_link':
    case 'vk_link':
      return {
        'required': 'Обязательное поле',
        'invalid': 'Неверный формат. Введите ссылку.',
      }

    case 'inner_url':
    case 'url':
      return {
        'unique': 'Данный адрес уже занят',
        'alphanumeric': 'alphanumeric url'
      }

    case 'type':
    case 'group':
      return {
        'null': 'Выберите тип'
      }
    
    case 'position':
      return {
        'max_value': 'Слишком большое значение',
        'min_value': 'Слишком маленькое значение'
      }

    case 'expires':
      return {
        'past_not_allowed': 'Необходимо ввести будущее время'
      }
  
    default:
      return {
        'unique': 'Занят',
        'blank': 'Пустое поле',
        'invalid_social_token': 'Invalid social token',
        'cannot_connect': 'Адрес недоступен',
        'social_taken': 'Данный cоциальный аккаунт занят',
        'required': 'Обязательное поле',
        'invalid': 'Неверный формат',
      }
      break;
  }
}