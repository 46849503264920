
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Project } from '@/types';
import { State } from 'vuex-class';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

@Component({
  components: {
    Swiper,
    SwiperSlide,
  }
})
export default class ScreenshotsCarouselComponent extends Vue {
  @Prop({ type: Array, default: [] }) screenshots: Project.Screenshot[];
  @Prop({ type: String, default: '' }) video_link: string;
  @Prop({ type: Boolean, default: false }) isShown: boolean;
  @Prop({ type: Number, default: 0 }) currentSlide: number;

  isShownLocal: boolean = false;
  scrollTop: number = 0;
  swiperOption = {
    slidesPerView: 1,
    spaceBetween: 30,
    speed: 300
  }

  get mediaList() {
    let list = [];
    if (this.video_link) list.push({ video_link: this.video_link })
    list.push(...this.screenshots)
    return list;
  }
  
  @Watch('isShown') async onIsShownChanged(val: boolean) {
    await this.toggleBodyHidden(val);
    this.isShownLocal = val;
    if (val) {
      this.$nextTick(() => {
        if (this.$refs.carousel && (this.$refs.carousel as any).$swiper) {
          (this.$refs.carousel as any).$swiper.slideTo(this.currentSlide - 1, 0);
        }
      })
    }
  }

  pageChanged() { 
    const slideIndex = (this.$refs.carousel as any).$swiper.activeIndex + 1;
    this.$emit('setCurrentSlide', slideIndex);
  }

  toggleBodyHidden(open: boolean) {
    const userAgent = navigator.userAgent;
    if (open) document.body.classList.add('hidden');
    else document.body.classList.remove('hidden');
  }

  slidePrev() {
    if (this.mediaList && this.mediaList.length > 0 && this.currentSlide > 1) {
      (this.$refs.carousel as any).$swiper.slideTo(this.currentSlide - 2);
    }
  }

  slideNext() {
    if (this.mediaList && this.mediaList.length > 0 && this.currentSlide < this.mediaList.length) {
      (this.$refs.carousel as any).$swiper.slideTo(this.currentSlide);
    }
  }

  close() {
    this.$emit('close');
    this.toggleBodyHidden(false)
  }

  onEscapeKeyUp(event) {
    if (this.isShown) {
      if (event.key === 'Escape') this.close();
      else if (event.key === 'ArrowRight') this.slideNext();
      else if (event.key === 'ArrowLeft') this.slidePrev();
    }
  }

  mounted() {
    window.addEventListener('keyup', this.onEscapeKeyUp);
  }

  async beforeDestroy() {
    await this.toggleBodyHidden(false)
    window.removeEventListener('keyup', this.onEscapeKeyUp);
  }
}
