
import { Component, Vue } from 'vue-property-decorator';
import { Project } from '@/types'
import { getProjectReports, reviewReport, getProjectByID } from '@/api/services/servers';
import ButtonComponent from '@/components/ButtonComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

@Component({
  components: {
    ButtonComponent,
    SpinnerComponent
  }
})
export default class ProjectReportLayout extends Vue {
  project: Project.Project = null;
  isPageLoading: boolean = true;
  isLoadingMore: boolean = false;
  isViewLoading: boolean = false;
  reportsList: Project.ReportItem[] = [];
  reportsCount: number = 0;

  async setViewed() {
    this.isViewLoading = true;
    try {
      const resp = await reviewReport(this.reportsList.map(it => it.id));
      this.reportsCount = 0;
    } catch (error) {
      console.log(error);
    } finally {
      this.isViewLoading = false;
    }
  }

  async loadMore() {
    const projectID = this.$route.params.id;
    this.isLoadingMore = true;
    try {
      const reports = await getProjectReports(projectID, false, this.reportsList.length, 10);
      this.reportsList.push(...reports.data.results);
      this.reportsCount = reports.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoadingMore = false;
    }
  }

  async mounted() {
    const projectID = this.$route.params.id;
    this.isPageLoading = true;
    try {
      Promise.all([getProjectByID(projectID), getProjectReports(projectID, false, 0, 10)])
        .then(res => {
          this.project = res[0].data;
          this.reportsList = res[1].data.results;
          this.reportsCount = res[1].data.count;
        })
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
