
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Wallet } from '@/types';
import { getPromocodesList, createPromocode, deactivatePromocode, updatePromocode } from '@/api/services/wallet';
import { errorHandler } from '@/utils';
import debounce from 'lodash/debounce';
import SearchComponent from '@/components/SearchComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import TextModalComponent from '@/components/TextModalComponent.vue';
import InputComponent from '@/components/InputComponent.vue';

@Component({
  metaInfo: {
    title: 'Управление промокодами'
  },
  components: {
    SearchComponent,
    SpinnerComponent,
    ButtonComponent,
    ModalComponent,
    TextModalComponent,
    InputComponent
  }
})
export default class PromocodesPage extends Vue {
  isPageLoading: boolean = true;
  isAddModalShown: boolean = false;
  isEditModalShown: boolean = false;
  isDeactivateModalShown: boolean = false;
  query: string = '';
  promocodesList: Wallet.PromocodeItem[] = [];
  promocodesCount: number = 0;
  selectedPromocode: Wallet.PromocodeForm = new Wallet.PromocodeForm();
  isLoadingMore: boolean = false;

  onQueryChangeDebounced: Function = debounce(this.onQueryChange, 350);

  @Watch('query') queryWatcher(value: string) {
    this.isPageLoading = true;
    this.onQueryChangeDebounced();
  }

  async onQueryChange() {
    try {
      this.$router.push({ query: this.query ? { q: this.query } : null })
      const resp = await getPromocodesList(0, 10, this.query);
      this.promocodesList = resp.data.results;
      this.promocodesCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
  
  openAddModal() {
    this.selectedPromocode = new Wallet.PromocodeForm();
    this.$nextTick(() => {
      this.isAddModalShown = true;
    })
  }

  async createPromocode() {
    this.selectedPromocode.isLoading = true;
    try {
      if (!this.selectedPromocode.isValid()) return

      const promo = await createPromocode(this.selectedPromocode.code.value, this.selectedPromocode.discount_percent.value, this.selectedPromocode.expires.value);
      this.promocodesList.unshift(promo.data)
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (this.selectedPromocode[it]) this.selectedPromocode[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedPromocode.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedPromocode.isLoading = false;
    }
  }

  openDeactivateModal(promo: Wallet.PromocodeItem) {
    this.selectedPromocode = new Wallet.PromocodeForm(promo);
    this.$nextTick(() => {
      this.isDeactivateModalShown = true;
    })
  }

  async deactivate() {
    this.selectedPromocode.isLoading = true;
    try {

      const promo = await deactivatePromocode(this.selectedPromocode.id);
      let edited = this.promocodesList.find(it => it.id === this.selectedPromocode.id);
      edited.state = promo.data.state;
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (this.selectedPromocode[it]) this.selectedPromocode[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedPromocode.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedPromocode.isLoading = false;
    }
  }

  openEditModal(promo: Wallet.PromocodeItem) {
    this.selectedPromocode = new Wallet.PromocodeForm(promo);
    this.$nextTick(() => {
      this.isEditModalShown = true;
    })
  }

  async update() {
    this.selectedPromocode.isLoading = true;
    try {
      if (!this.selectedPromocode.isValid()) return

      const promo = await updatePromocode(this.selectedPromocode.id, this.selectedPromocode.code.value, this.selectedPromocode.discount_percent.value, this.selectedPromocode.expires.value);
      let edited = this.promocodesList.find(it => it.id === this.selectedPromocode.id);
      edited.code = promo.data.code;
      edited.discount_percent = promo.data.discount_percent;
      edited.expires = promo.data.expires;
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (this.selectedPromocode[it]) this.selectedPromocode[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedPromocode.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedPromocode.isLoading = false;
    }
  }

  closeModals() {
    this.isAddModalShown = false;
    this.isEditModalShown = false;
    this.isDeactivateModalShown = false;

    this.$nextTick(() => {
      this.selectedPromocode = new Wallet.PromocodeForm();
    })
  }

  async loadMore() {
    this.isLoadingMore = true;
    try {
      const resp = await getPromocodesList(this.promocodesList.length, 10, this.query);
      this.promocodesList.push(...resp.data.results);
      this.promocodesCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoadingMore = false;
    }
  }

  async mounted() {
    const q = this.$route.query && this.$route.query.q ? String(this.$route.query.q) : '';
    this.query = q;
    this.isPageLoading = true;
    try {
      const resp = await getPromocodesList(0, 10, q);
      this.promocodesList = resp.data.results;
      this.promocodesCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
