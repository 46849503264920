
import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';

import { Project } from '@/types';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ShowMoreComponent from '@/components/ShowMoreComponent.vue';
import ScreenshotsSliderComponent from '@/components/ScreenshotsSliderComponent.vue';
import TableRowServerItemComponent from '@/components/TableRowServerItemComponent.vue';

@Component({
  components: {
    ButtonComponent,
    ShowMoreComponent,
    ScreenshotsSliderComponent,
    TableRowServerItemComponent
  }
})
export default class ProjectDetailsPage extends Vue {
  @Prop({ type: Object }) project: Project.Project;

  @State versions: Project.VersionsResponse;
  @Getter getFilteredTags: Project.TagsResponse;

  baseURL: string = process.env.VUE_APP_FRONT_URL;
  baseDjangoURL: string = process.env.VUE_APP_DJANGO_BASE_URL;
  openedServerIndex: number = null;

  get lobbyAddress() {
    if (this.project.servers && this.project.servers.length > 0) {
      const firstServer = this.project.servers[0];
      if (this.project.lobby) {
        const lobbyServer = this.project.servers.find(it => it.id === this.project.lobby);
        return lobbyServer ? `${lobbyServer.address}:${lobbyServer.port}` : `${firstServer.address}:${firstServer.port}`;
      } else return `${firstServer.address}:${firstServer.port}`
    } else return '';
  }
  get projectVersions() {
    const serverType = this.project.type && this.project.type != Project.ServerType.PC_MOD ? this.project.type : Project.ServerType.PC;
    if (this.versions && this.project.version_l != null || this.project.version_r != null) {
      const start = this.project.version_l != null ? this.versions[serverType].find(it => it.id === this.project.version_l) : null;
      const end = this.project.version_r != null && this.project.version_r != this.project.version_l ? this.versions[serverType].find(it => it.id === this.project.version_r) : null;
      return `${start.value}${(end) ? ` – ${end.value}` : ''}`;
    } else return ''
  }

  toggleDescription(index: number) {
    if (this.openedServerIndex === index) this.openedServerIndex = null
    else this.openedServerIndex = index;
  }

  getFullDescription(server: Project.Server) {
    return Object.values(this.getFilteredTags)
      .filter(group => group.items.some(it => server.tags.includes(it.id)))
      .map(group => { return { ...group, items: group.items.filter(it => server.tags.includes(it.id)) }})
  }
}
