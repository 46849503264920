
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { Comment, Common, User } from '@/types';
import { errorHandler } from '@/utils';
import { getCommentByID, getCommentLog, acceptComment, banComment, removeComment } from '@/api/services/comment';
import ButtonComponent from '@/components/ButtonComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ReportModalComponent from '@/components/ReportModalComponent.vue';
import TextModalComponent from '@/components/TextModalComponent.vue';

const user = namespace('user');

@Component({
  components: {
    ButtonComponent,
    SpinnerComponent,
    ReportModalComponent,
    TextModalComponent
  }
})
export default class CommentDetailsPage extends Vue {
  @user.State user: User.Profile;

  public metaInfo() {
    return {
      title: 'Test2'
    }
  }
  
  isPageLoading: boolean = true;
  comment: Comment.Comment = null;
  logsList: Comment.CommentLogItem[] = [];
  logsCount: number = 0;
  isReportModalShown: boolean = false;
  isUnblockModalShown: boolean = false;
  isRemoveModalShown: boolean = false;
  unbanForm: Common.UnbanForm = new Common.UnbanForm;
  banForm: Common.BanForm = new Common.BanForm;
  removeForm: Common.RemoveForm = new Common.RemoveForm;
  reasonsCustom: Common.BanReason[] = [
    Common.BanReason.FRAUD,
    Common.BanReason.SPAM,
    Common.BanReason.OBSCENE,
    Common.BanReason.OTHER
  ]

  getActionClass(action: Comment.LogActionType) {
    switch (action) {
      case Comment.LogActionType.accept: return 'green--text';
      case Comment.LogActionType.ban: return 'red--text';
      default: return 'green--text';
    }
  }
  getActionText(action: Comment.LogActionType) {
    switch (action) {
      case Comment.LogActionType.accept: return 'Принят';
      case Comment.LogActionType.ban: return 'Отклонен';
      default: return 'Принят';
    }
  }
  getBanText(reason: Common.BanReason) {
    switch (reason) {
      case Common.BanReason.FRAUD: return 'Мошенничество';
      case Common.BanReason.WRONG_CATEGORIES: return 'Неверные категории';
      case Common.BanReason.SPAM: return 'Спам';
      case Common.BanReason.OBSCENE: return 'Нецензурная лексика';
      case Common.BanReason.OTHER: return 'Другое';
      default: return ''
    }
  }

  async accept() {
    this.unbanForm.isLoading = true;
    try {
      const resp = await acceptComment(this.comment.id);
      this.isUnblockModalShown = false;
      this.logsList.unshift(resp.data);
      this.comment.state = Comment.CommentState.ok;
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.unbanForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.unbanForm.isLoading = false;
    }
  }

  async report() {
    this.banForm.isLoading = true;
    this.banForm.resetErrors();
    try {
      if (!this.banForm.isValid()) return;

      const resp = await banComment(
        this.comment.id,
        this.banForm.ban_reason,
        this.banForm.ban_reason === Common.BanReason.OTHER ? this.banForm.ban_details.value : undefined);
      this.isReportModalShown = false;
      this.logsList.unshift(resp.data);
      this.comment.state = Comment.CommentState.banned;
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (it === 'ban_reason') this.banForm.ban_reasonError = errorHandler(it, errResponse[it][0]);
        else if (this.banForm[it]) this.banForm[it].error = errorHandler(it, errResponse[it][0]);
        else this.banForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.banForm.isLoading = false;
    }
  }

  async remove() {
    this.removeForm.isLoading = true;

    try {
      await removeComment(this.comment.id);
      this.$router.push('/comments')
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.removeForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.removeForm.isLoading = false;
    }
  }

  async mounted() {
    const commentID = this.$route.params.id;
    const _self = this;
    this.isPageLoading = true;
    try {
      await Promise.all([getCommentByID(commentID), getCommentLog(commentID)])
        .then(res => {
          _self.comment = res[0].data;
          _self.logsList = res[1].data.results;
          _self.logsCount = res[1].data.count;
        })
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
