import { format, formatDistanceToNow, isPast } from 'date-fns';
import { ru } from 'date-fns/locale';

declare global {
  interface Date {
    formatByPattern: (_format) => string;
    formatDate: () => string;
    formatDateTimeMonthFully: ()  => string;
    formatDateNumeric: () => string;
    formatDateTime: () => string;
    formatFromNow: () => string;
    formatExpireDateTime: () => string;
  }
}

Date.prototype.formatByPattern = function(_format: string) : string {
  return format(this, _format, {locale: ru})
};

Date.prototype.formatDate = function() : string {
  return format(this, 'dd MMMM yyyy', {locale: ru})
};

Date.prototype.formatDateTimeMonthFully = function() : string {
  return format(this, 'dd MMMM yyyy, kk:mm', {locale: ru})
};

Date.prototype.formatDateNumeric = function() : string {
  return format(this, 'dd.MM.yyyy', {locale: ru})
};

Date.prototype.formatDateTime = function() : string {
  return format(this, 'dd.MM.yyyy, kk:mm', {locale: ru})
};

Date.prototype.formatFromNow = function() : string {
  return isPast(this) ? `${formatDistanceToNow(this, {locale: ru})} назад` :  `через ${formatDistanceToNow(this, {locale: ru})}`;
};

Date.prototype.formatExpireDateTime = function() : string {
  return format(this, 'yyyy-MM-dd, kk:mm', {locale: ru})
};
