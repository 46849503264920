
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getQuickFiltersGrouped, createQuickFilter, updateQuickFilter, deleteQuickFilter } from '@/api/services/quick-filters';
import { QuickFilters, UI, Project } from '@/types';
import { errorHandler } from '@/utils';
import { State } from 'vuex-class';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import TextModalComponent from '@/components/TextModalComponent.vue';
import DropdownComponent from '@/components/DropdownComponent.vue';
import FilterSelectDropdownComponent from '@/components/FilterSelectDropdownComponent.vue';
import InputComponent from '@/components/InputComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

@Component({
  components: {
    ButtonComponent,
    ModalComponent,
    TextModalComponent,
    DropdownComponent,
    FilterSelectDropdownComponent,
    InputComponent,
    SpinnerComponent
  }
})
export default class QuickFiltersPage extends Vue {
  isPageLoading: boolean = true;
  isAddModalShown: boolean = false;
  isDeleteModalShown: boolean = false;
  isEditModalShown: boolean = false;
  selectedFilter: QuickFilters.QuickFilterForm = new QuickFilters.QuickFilterForm();
  quickFiltersGrouped: QuickFilters.QuickFiltersGroupedResponse[] = [];

  tabsList: UI.DropdownItem[] = [
    { name: 'Все серверы', value: QuickFilters.Tabs.ALL },
    { name: 'PC Classic', value: QuickFilters.Tabs.PC },
    { name: 'PC Mods', value: QuickFilters.Tabs.PC_MOD },
    { name: 'Bedrock', value: QuickFilters.Tabs.BEDROCK },
    { name: 'Новые сервера', value: QuickFilters.Tabs.NEW }
  ];
  has_one_list: UI.DropdownItem[] = [
    { name: 'С мини-играми', value: QuickFilters.HasOne.MINI },
    { name: 'С модами', value: QuickFilters.HasOne.MOD },
    { name: 'С плагинами', value: QuickFilters.HasOne.PLUGIN },
  ]

  @State versions: Project.VersionsResponse;
  @State tags: Project.TagsResponse;

  get quickFiltersList(): QuickFilters.QuickFilterItem[] {
    const hash = this.$route.hash;
    const tab = hash ? hash.slice(1) : 'all';
    const group = this.quickFiltersGrouped.find(it => it.tab === tab.replace(/_/g, " "));
    return group ? group.quick_filters : [];
  }

  get filteredVersions() {
    if (this.selectedFilter.tab === null) return [];
  
    return this.selectedFilter.tab.value === QuickFilters.Tabs.ALL || this.selectedFilter.tab.value === QuickFilters.Tabs.NEW
      ? Object.values(this.versions).reduce((prev, cur) => [...prev, ...cur], [])
      : this.selectedFilter.tab.value === QuickFilters.Tabs.PC_MOD
      ? this.versions.pc
      : this.versions[this.selectedFilter.tab.value];
  }

  get filteredTags() {
    if (this.selectedFilter.tab === null) return [];
  
    const list = Object.values(this.tags).reduce((prev, cur) => [...prev, ...cur.items], []);
    return this.selectedFilter.tab.value === QuickFilters.Tabs.BEDROCK
      ? list.filter(it => it.group != Project.TagGroupType.MOD)
      : list;
  }

  filterVersionsFuction(it: Project.Version, query: string) {
    return it.value.includes(query.toLowerCase()) || it.value.split('.').join("").includes(query.split('.').join(""))
  }

  filterTagsFuction(it: Project.Tag, query: string) {
    return String(it.id).includes(query) || it.name.toLowerCase().includes(query.toLowerCase()) || it.value.toLowerCase().includes(query.toLowerCase());
  }

  openAddModal() {
    const hash = this.$route.hash;
    const tabValue = hash ? hash.slice(1) : 'all';
    const tab = this.tabsList.find(it => it.value === (tabValue === 'pc_mod' ? QuickFilters.Tabs.PC_MOD : tabValue));
    this.selectedFilter = new QuickFilters.QuickFilterForm(null, tab);
    this.$nextTick(() => {
      this.isAddModalShown = true;
    })
  }

  async addFilter() {
    this.selectedFilter.isLoading = true;
    this.selectedFilter.resetErrors();
    try {
      if (!this.selectedFilter.isValid()) return;

      const resp = await createQuickFilter(
        this.selectedFilter.tab.value,
        parseInt(this.selectedFilter.score.value),
        this.selectedFilter.version ? this.selectedFilter.version.id : null,
        this.selectedFilter.tag ? this.selectedFilter.tag.id : null,
        this.selectedFilter.has_one ? this.selectedFilter.has_one.value : null);

      const group = this.quickFiltersGrouped.find(it => it.tab === resp.data.tab);
      const versionsList = Object.values(this.versions).reduce((prev, cur) => [...prev, ...cur], []);
      const tagsList = Object.values(this.tags).reduce((prev, cur) => [...prev, ...cur.items], []);
      if (group) group.quick_filters.push(new QuickFilters.QuickFilterItem(resp.data, versionsList, tagsList, this.has_one_list));
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (it === 'tab') this.selectedFilter.tab_error = errorHandler(it, errResponse[it][0]);
        else if (it === 'version') this.selectedFilter.version_error = errorHandler(it, errResponse[it][0]);
        else if (it === 'tag') this.selectedFilter.tag_error = errorHandler(it, errResponse[it][0]);
        else if (this.selectedFilter[it]) this.selectedFilter[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedFilter.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedFilter.isLoading = false;
    }
  }
  
  openEditModal(item: QuickFilters.QuickFilterItem) {
    const tab = this.tabsList.find(it => it.value === item.tab);
    this.selectedFilter = new QuickFilters.QuickFilterForm(item, tab);
    this.$nextTick(() => {
      this.isEditModalShown = true;
    })
  }

  async updateFilter() {
    this.selectedFilter.isLoading = true;
    this.selectedFilter.resetErrors();
    try {
      if (!this.selectedFilter.isValid()) return;

      const resp = await updateQuickFilter(
        this.selectedFilter.id,
        this.selectedFilter.tab.value,
        parseInt(this.selectedFilter.score.value),
        this.selectedFilter.version ? this.selectedFilter.version.id : null,
        this.selectedFilter.tag ? this.selectedFilter.tag.id : null,
        this.selectedFilter.has_one ? this.selectedFilter.has_one.value : null);

      const group = this.quickFiltersGrouped.find(it => it.tab === resp.data.tab);
      const itemIndex = group ? group.quick_filters.findIndex(it => it.id === resp.data.id) : -1;
      if (itemIndex >= 0) {
        const versionsList = Object.values(this.versions).reduce((prev, cur) => [...prev, ...cur], []);
        const tagsList = Object.values(this.tags).reduce((prev, cur) => [...prev, ...cur.items], []);
        group.quick_filters[itemIndex] = new QuickFilters.QuickFilterItem(resp.data, versionsList, tagsList, this.has_one_list) 
      }
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (it === 'tab') this.selectedFilter.tab_error = errorHandler(it, errResponse[it][0]);
        else if (it === 'version') this.selectedFilter.version_error = errorHandler(it, errResponse[it][0]);
        else if (it === 'tag') this.selectedFilter.tag_error = errorHandler(it, errResponse[it][0]);
        else if (this.selectedFilter[it]) this.selectedFilter[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedFilter.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedFilter.isLoading = false;
    }
  }
  
  openDeleteModal(item: QuickFilters.QuickFilterItem) {
    this.selectedFilter = new QuickFilters.QuickFilterForm(item);
    this.$nextTick(() => {
      this.isDeleteModalShown = true;
    })
  }

  async removeFilter() {
    this.selectedFilter.isLoading = true;
    try {
      await deleteQuickFilter(this.selectedFilter.id);
      const group = this.quickFiltersGrouped.find(it => it.tab === this.selectedFilter.tab.value);
      const itemIndex = group ? group.quick_filters.findIndex(it => it.id === this.selectedFilter.id) : -1;
      if (itemIndex >= 0) group.quick_filters.splice(itemIndex, 1);
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.selectedFilter.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedFilter.isLoading = false;
    }
  }

  closeModals() {
    this.isDeleteModalShown = false;
    this.isEditModalShown = false;
    this.isAddModalShown = false;

    this.$nextTick(() => {
      this.selectedFilter = new QuickFilters.QuickFilterForm();
    })
  }

  async mounted() {
    this.isPageLoading = true;
    try {
      const versionsList = Object.values(this.versions).reduce((prev, cur) => [...prev, ...cur], []);
      const tagsList = Object.values(this.tags).reduce((prev, cur) => [...prev, ...cur.items], []);
      const resp = await getQuickFiltersGrouped();
      this.quickFiltersGrouped = resp.data.map(it => new QuickFilters.QuickFiltersGroupedResponse(it, versionsList, tagsList, this.has_one_list));
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
