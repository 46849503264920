
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Common, UI } from '@/types';
import ModalComponent from '@/components/ModalComponent.vue';
import RadioComponent from '@/components/RadioComponent.vue';
import TextareaComponent from '@/components/TextareaComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

@Component({
  components: {
    ModalComponent,
    RadioComponent,
    TextareaComponent,
    SpinnerComponent,
    ButtonComponent
  }
})
export default class ReportModalComponent extends Vue {
  @Prop({ type: Boolean, default: false }) isShown: boolean;
  @Prop({ type: Object }) banForm: Common.BanForm;
  @Prop({ type: Array, default: [] }) reasonsCustom: Common.BanReason[];

  reasonsList = [
    { value: Common.BanReason.WRONG_CATEGORIES, name: 'Неверные категории' },
    { value: Common.BanReason.OBSCENE, name: 'Нецензурная лексика' },
    { value: Common.BanReason.FRAUD, name: 'Мошенничество' },
    { value: Common.BanReason.SPAM, name: 'Спам' },
    { value: Common.BanReason.OTHER, name: 'Другое' }
  ];

  get reasons(): UI.DropdownItem[] {
    return this.reasonsCustom && this.reasonsCustom.length > 0 ? this.reasonsList.filter(it => this.reasonsCustom.includes(it.value)) : this.reasonsList;
  }

  get isNeedDetails() { return this.banForm.ban_reason === Common.BanReason.OTHER; }

  onSubmit() { this.$emit('submit'); }
}
