
import { Component, Vue } from 'vue-property-decorator';
import { Project } from '@/types';
import { State, Action } from 'vuex-class';
import { errorHandler } from '@/utils';
import { createTag, updateTag, deleteTag } from '@/api/services/servers';
import SearchComponent from '@/components/SearchComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import DropdownComponent from '@/components/DropdownComponent.vue';
import InputComponent from '@/components/InputComponent.vue';
import TextareaComponent from '@/components/TextareaComponent.vue';

@Component({
  metaInfo: {
    title: 'Модерация категорий'
  },
  components: {
    SearchComponent,
    ButtonComponent,
    ModalComponent,
    SpinnerComponent,
    DropdownComponent,
    InputComponent,
    TextareaComponent
  }
})
export default class CategoriesPage extends Vue {
  @State tags: Project.TagsResponse;
  @Action updateTagsList;

  query: string = '';
  selectedTag: Project.TagForm = new Project.TagForm();
  isDeleteModalShown: boolean = false;
  isAddModalShown: boolean = false;
  isEditModalShown: boolean = false;
  
  get filteredTags() {
    if (this.tags === null || this.tags === undefined) return [];

    const filterFunc = (it: Project.Tag) => {
      return String(it.id).includes(this.query) || it.name.toLowerCase().includes(this.query.toLowerCase()) || it.value.toLowerCase().includes(this.query.toLowerCase()) || it.description.toLowerCase().includes(this.query.toLowerCase())
    }
    const list = Object.values(this.tags).map(it => { return { ...it, items: it.items.sort((x, y) => x.id > y.id ? 1 : x.id < y.id ? -1 : 0) } });
    return this.query.length > 0 ? list.filter(group => group.items.some(it => filterFunc(it))).map(group => { return { ...group, items: group.items.filter(it => filterFunc(it)) } }) : list;
  }

  get tagGroups() {
    return this.tags ? Object.values(Project.TagGroupType).map(it => { return { type: it, name: this.tags[it].name } }) : []
  }

  openEditModal(tag: Project.Tag) {
    this.selectedTag = new Project.TagForm(tag, this.tags[String(tag.group)].name);
    this.$nextTick(() => {
      this.isEditModalShown = true;
    })
  }
  async editTag() {
    this.selectedTag.isLoading = true;
    try {
      await updateTag(this.selectedTag.id,
                      this.selectedTag.name.value,
                      this.selectedTag.group.type,
                      this.selectedTag.value.value,
                      this.selectedTag.description.value);
      await this.updateTagsList();
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (this.selectedTag[it]) this.selectedTag[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedTag.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedTag.isLoading = false;
    }
  }

  openDeleteModal(tag: Project.Tag) {
    this.selectedTag = new Project.TagForm(tag, this.tags[String(tag.group)].name);
    this.$nextTick(() => {
      this.isDeleteModalShown = true;
    })
  }
  async removeTag() {
    this.selectedTag.isLoading = true;
    try {
      await deleteTag(this.selectedTag.id);
      await this.updateTagsList();
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.selectedTag.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedTag.isLoading = false;
    }
  }

  openAddModal() {
    this.selectedTag = new Project.TagForm();
    this.$nextTick(() => {
      this.isAddModalShown = true;
    })
  }
  async addTag() {
    this.selectedTag.isLoading = true;
    try {
      await createTag(this.selectedTag.name.value,
                      this.selectedTag.group.type,
                      this.selectedTag.value.value,
                      this.selectedTag.description.value);
      await this.updateTagsList();
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (this.selectedTag[it]) this.selectedTag[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedTag.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedTag.isLoading = false;
    }
  }

  closeModals() {
    this.isDeleteModalShown = false;
    this.isAddModalShown = false;
    this.isEditModalShown = false;

    this.$nextTick(() => {
      this.selectedTag = new Project.TagForm();
    })
  }
}
