
import { Component, Vue, Prop } from 'vue-property-decorator';
import ModalComponent from '@/components/ModalComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

@Component({
  components: {
    ModalComponent,
    ButtonComponent,
    SpinnerComponent
  }
})
export default class TextModalComponent extends Vue {
  @Prop({ type: Boolean, default: false }) isShown: boolean;
  @Prop({ type: Boolean, default: false }) isLoading: boolean;
  @Prop({ type: String, default: '' }) error: string;

  onSubmit() { this.$emit('submit'); }
}
