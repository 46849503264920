
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { User } from '@/types';
import InputComponent from '@/components/InputComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

@Component({
  components: {
    InputComponent,
    ButtonComponent
  }
})
export default class FilterSelectDropdownComponent extends Vue {
  @Prop({ type: Object }) value: any;
  @Prop({ type: Array }) options: any[];
  @Prop({ type: String, default: 'name' }) displayKey: string;
  @Prop({ type: String, default: '' }) placeholder: string;
  @Prop({ type: Function }) filterFunction: Function;

  query: string = '';
  isDropdownOpen: boolean = false;

  get filteredList() {
    return this.query.length > 0 ? this.options.filter(it => this.filterFunction(it, this.query)) :  this.options;
  }
  
  openDropdown() {
    this.isDropdownOpen = true;
    this.$nextTick(() => {
      ((this.$refs.search as Vue).$refs.input as HTMLInputElement).focus();
    })
  }
  closeDropdown() { this.isDropdownOpen = false; }
  select(item) {
    this.$emit('input', item);
    this.$nextTick(() => {
      setTimeout(this.closeDropdown);
    })
  }
}
