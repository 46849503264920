
import { Vue, Component, Prop } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';

@Component({
  components: {
    ButtonComponent
  }
})
export default class DropdownComponent extends Vue {
  @Prop({ type: Object }) value: any;
  @Prop({ type: Array }) options: any[];
  @Prop({ type: String, default: 'name' }) displayKey: string;
  @Prop({ type: String, default: '' }) placeholder: string;

  isDropdownOpen: boolean = false;
  
  closeDropdown() { this.isDropdownOpen = false; }
  select(value) {
    this.$emit('input', value);
    this.closeDropdown();
  }
}
