
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Project, User, Common, UI } from '@/types';
import { errorHandler } from '@/utils';
import addDays from 'date-fns/addDays';
import { getProjectByID, moderateProject, deleteProject } from '@/api/services/servers';
import ButtonComponent from '@/components/ButtonComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ReportModalComponent from '@/components/ReportModalComponent.vue';
import TextModalComponent from '@/components/TextModalComponent.vue';
import DropdownComponent from '@/components/DropdownComponent.vue';
import TransferModalComponent from '@/components/TransferModalComponent.vue';

const user = namespace('user');

@Component({
  components: {
    ButtonComponent,
    SpinnerComponent,
    ReportModalComponent,
    TextModalComponent,
    DropdownComponent,
    TransferModalComponent,
  }
})
export default class ProjectLayout extends Vue {
  @user.State user: User.Profile;

  isPageLoading: boolean = true;
  project: Project.Project = null;
  isBanModalShown: boolean = false;
  isPenaltyModalShown: boolean = false;
  isUnBanModalShown: boolean = false;
  isDeleteModalShown: boolean = false;
  isTransferModalShown: boolean = false;
  banForm: Common.BanForm = new Common.BanForm;
  penaltyForm: Project.PenaltyForm = new Project.PenaltyForm;
  unbanForm: UI.Form = new UI.Form;
  deleteForm: UI.Form = new UI.Form;

  penalizePointsValues = new Project.PenalizePointsValues().getAsArray();
  penalizeExpireValues = new Project.PenalizeExpireValues().getAsArray();
  reasonsCustom: Common.BanReason[] = [
    Common.BanReason.WRONG_CATEGORIES,
    Common.BanReason.FRAUD,
    Common.BanReason.SPAM,
    Common.BanReason.OTHER
  ]

  async deleteProject() {
    this.deleteForm.isLoading = true;
    try {
      await deleteProject(this.project.id);
      this.isDeleteModalShown = false;
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.deleteForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.deleteForm.isLoading = false;
    }
  }
  
  async unban() {
    this.unbanForm.isLoading = true;
    try {
      const resp = await moderateProject(
        this.project.id,
        this.project.state === Project.ProjectState.BANNED ? Project.ModerationAction.UNBAN :Project.ModerationAction.APPROVE
      );
      this.project = resp.data;
      this.isUnBanModalShown = false;
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.unbanForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.unbanForm.isLoading = false;
    }
  }

  async ban() {
    this.banForm.isLoading = true;
    this.banForm.resetErrors();
    try {
      if (!this.banForm.isValid()) return;

      const resp = await moderateProject(
        this.project.id,
        this.project.state === Project.ProjectState.OK ? Project.ModerationAction.BAN : Project.ModerationAction.DECLINE,
        this.banForm.ban_reason,
        this.banForm.ban_reason === Common.BanReason.OTHER ? this.banForm.ban_details.value : undefined);
      this.project = resp.data;
      this.isBanModalShown = false;
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (it === 'reason') this.banForm.ban_reasonError = errorHandler(it, errResponse[it][0]);
        else if (it === 'details') this.banForm.ban_details.error = errorHandler(it, errResponse[it][0]);
        else if (this.banForm[it]) this.banForm[it].error = errorHandler(it, errResponse[it][0]);
        else this.banForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.banForm.isLoading = false;
    }
  }

  async setPenalty() {
    this.penaltyForm.isLoading = true;
    this.penaltyForm.resetErrors();
    try {
      if (!this.penaltyForm.isValid()) return;

      const expires = addDays(new Date(), this.penaltyForm.penalty_expires.value);
      const resp = await moderateProject(
        this.project.id,
        Project.ModerationAction.PENALIZE,
        this.penaltyForm.ban_reason,
        this.penaltyForm.ban_reason === Common.BanReason.OTHER ? this.penaltyForm.ban_details.value : undefined,
        this.penaltyForm.penalty_points.value,
        expires
      );
      this.project = resp.data;
      this.isPenaltyModalShown = false;
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (it === 'reason') this.banForm.ban_reasonError = errorHandler(it, errResponse[it][0]);
        else if (it === 'details') this.banForm.ban_details.error = errorHandler(it, errResponse[it][0]);
        else if (it === 'penalty_points') this.penaltyForm.penalty_pointsError = errorHandler(it, errResponse[it][0]);
        else if (it === 'penalty_expires') this.penaltyForm.penalty_expiresError = errorHandler(it, errResponse[it][0]);
        else if (this.penaltyForm[it]) this.penaltyForm[it].error = errorHandler(it, errResponse[it][0]);
        else this.penaltyForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.penaltyForm.isLoading = false;
    }
  }

  afterTransfer (owner: User.Profile) {
    this.project.owner = owner
  }

  async mounted() {
    const projectID = this.$route.params.id;
    this.isPageLoading = true;
    try {
      const project = await getProjectByID(projectID);
      this.project = project.data;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
