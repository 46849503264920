
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Project } from '@/types';
import { getReportsGrouped } from '@/api/services/servers'
import debounce from 'lodash/debounce';
import SearchComponent from '@/components/SearchComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

@Component({
  metaInfo: {
    title: 'Жалобы'
  },
  components: {
    SearchComponent,
    ButtonComponent,
    SpinnerComponent
  }
})
export default class ReportsPage extends Vue {
  isPageLoading: boolean = true;
  query: string = this.$route.query && this.$route.query.q ? String(this.$route.query.q) : '';
  groupsList: Project.ReportGroup[] =  [];
  groupsCount: number = 0;
  isLoadingMore: boolean = false;
  onQueryChangeDebounced: Function = debounce(this.onQueryChange, 350);

  @Watch('query') queryWatcher(value: string) {
    this.isPageLoading = true;
    this.onQueryChangeDebounced();
  }

  async onQueryChange() {
    try {
      this.$router.push({ query: this.query ? { q: this.query } : null })
      const resp = await getReportsGrouped(0, 10, this.query);
      this.groupsList = resp.data.results;
      this.groupsCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }

  async loadMore() {
    this.isLoadingMore = true;
    try {
      const resp = await getReportsGrouped(this.groupsList.length, 10, this.query);
      this.groupsList.push(...resp.data.results);
      this.groupsCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoadingMore = false;
    }
  }

  async mounted() {
    const q = this.$route.query && this.$route.query.q ? String(this.$route.query.q) : '';
    this.query = q;
    this.isPageLoading = true;
    try {
      const resp = await getReportsGrouped(0, 10, q);
      this.groupsList = resp.data.results;
      this.groupsCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
