
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Project, Common } from '@/types'
import ButtonComponent from '@/components/ButtonComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

@Component({
  components: {
    ButtonComponent,
    SpinnerComponent
  }
})
export default class ProjectReportsPage extends Vue {
  @Prop({ type: Array, default: [] }) newReportsList: Project.ReportItem[];
  @Prop({ type: Number, default: 0 }) newReportsCount: number;
  @Prop({ type: Boolean, default: false }) isPageLoading: boolean;
  @Prop({ type: Boolean, default: false }) isLoadingMore: boolean;

  getReportType(type: Common.BanReason) {
    switch (type) {
      case Common.BanReason.WRONG_CATEGORIES: return 'Неверные категории';
      case Common.BanReason.FRAUD: return 'Мошенничество';
      case Common.BanReason.SPAM: return 'Спам';
      case Common.BanReason.OBSCENE: return 'Нецензурная лексика';
      case Common.BanReason.OTHER: return 'Другое';
      default: return 'Другое';
    }
  }

  loadMore() { this.$emit('loadMore') }
}
