
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const user = namespace('user');
import { User } from '@/types';
import { setUserRole, getModersList } from '@/api/services/users';
import { errorHandler } from '@/utils';
import debounce from 'lodash/debounce';
import SearchComponent from '@/components/SearchComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import RadioComponent from '@/components/RadioComponent.vue';
import DropdownComponent from '@/components/DropdownComponent.vue';
import InputComponent from '@/components/InputComponent.vue';
import UserSelectDropdownComponent from '@/components/UserSelectDropdownComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

@Component({
  metaInfo: {
    title: 'Управление командой'
  },
  components: {
    SearchComponent,
    ButtonComponent,
    ModalComponent,
    RadioComponent,
    DropdownComponent,
    InputComponent,
    UserSelectDropdownComponent,
    SpinnerComponent
  }
})
export default class ModersPage extends Vue {
  @user.State user: User.Profile;
  @user.Action logout;

  isPageLoading: boolean = true;
  query: string = '';
  moders: User.Profile[] = [];
  selectedModer: User.ModerForm = new User.ModerForm();
  isEditModalShown: boolean = false;
  isDeleteModalShown: boolean = false;
  isAddModalShown: boolean = false;
  onQueryChangeDebounced: Function = debounce(this.onQueryChange, 350);
  
  roots = [
    {
      value: 'admin',
      name: 'Администратор',
      description: 'Может назначать и снимать администраторов, модерировать проекты, сервера и пользователей.'
    },
    {
      value: 'senior_moder',
      name: 'Старший модератор',
      description: 'Модерация проектов, серверов и пользователей.'
    },
    {
      value: 'moder',
      name: 'Модератор',
      description: 'Управление комментариями, жалобами и мониторинг действий в ЛК.'
    }
  ];

  @Watch('query') queryWatcher(value: string) {
    this.isPageLoading = true;
    this.onQueryChangeDebounced();
  }

  async onQueryChange() {
    try {
      this.$router.push({ query: this.query ? { q: this.query } : null })
      const modersList = await getModersList(this.query);
      this.moders = modersList.data.results;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }

  getUserRole(role: string) {
    return User.UserRole[role] ? User.UserRole[role] : ''
  }

  openAddModal() {
    this.selectedModer = new User.ModerForm();
    this.$nextTick(() => {
      this.isAddModalShown = true;
    })
  }
  async addModer() {
    this.selectedModer.resetErrors();
    this.selectedModer.isLoading = true;
    try {
      if (!this.selectedModer.isValid()) return

      const { data } = await setUserRole(this.selectedModer.user.id, this.selectedModer.role.value);
      this.moders.push(data);
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (this.selectedModer[it]) this.selectedModer[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedModer.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedModer.isLoading = false;
    }
  }

  openEditModal(moder: User.Profile) {
    this.selectedModer = new User.ModerForm(moder);
    this.$nextTick(() => {
      this.isEditModalShown = true;
    })
  }
  async editModer() {
    this.selectedModer.resetErrors();
    this.selectedModer.isLoading = true;
    try {
      if (!this.selectedModer.isValid()) return
      
      const { data } = await setUserRole(this.selectedModer.user.id, this.selectedModer.role.value);
      if (this.selectedModer.user.id === this.user.id) this.logout();

      let changed = this.moders.find(it => it.id === this.selectedModer.user.id);
      changed.role = data.role;
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (this.selectedModer[it]) this.selectedModer[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedModer.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedModer.isLoading = false;
    }
  }

  openDeleteModal(moder: User.Profile) {
    this.selectedModer = new User.ModerForm(moder);
    this.$nextTick(() => {
      this.isDeleteModalShown = true;
    })
  }
  async removeModerRole() {
    this.selectedModer.isLoading = true;
    try {
      await setUserRole(this.selectedModer.user.id, 'user');
      if (this.selectedModer.user.id === this.user.id) this.logout();
      
      const removedIndex = this.moders.findIndex(it => it.id === this.selectedModer.user.id);
      this.moders.splice(removedIndex, 1);
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (this.selectedModer[it]) this.selectedModer[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedModer.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedModer.isLoading = false;
    }
  }

  closeModals() {
    this.isDeleteModalShown = false;
    this.isAddModalShown = false;
    this.isEditModalShown = false;

    this.$nextTick(() => {
      this.selectedModer = new User.ModerForm();
    })
  }

  async mounted() {
    const q = this.$route.query && this.$route.query.q ? String(this.$route.query.q) : '';
    this.query = q;
    this.isPageLoading = true;
    try {
      const modersList = await getModersList(q);
      this.moders = modersList.data.results;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
