
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Edits } from '@/types';
import { getStats } from '@/api/services/edits';
import { errorHandler } from '@/utils';
import subBusinessDays from 'date-fns/subBusinessDays';
import startOfYear from 'date-fns/startOfYear';
import isAfter from 'date-fns/isAfter';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import LineChartComponent from '@/components/LineChartComponent.vue';
import DatepickerComponent from '@/components/VueDatepicker/Datepicker.vue';

@Component({
  metaInfo: {
    title: 'Модерация пользователей'
  },
  components: {
    SpinnerComponent,
    ButtonComponent,
    LineChartComponent,
    DatepickerComponent
  }
})
export default class StatsPage extends Vue {
  isPageLoading: boolean = true;
  error: string = '';
  stats: Edits.StatsResponse = null;
  rows = [
    { name: 'Количество уникальных посетителей', value: 'unique_visitors', dailyValue: 'unique_visitors' },
    { name: 'Количество просмотров страниц', value: 'pages_viewed', dailyValue: 'pages_viewed' },
    { name: 'Количество зарегистрированных пользователей', value: 'user_count', dailyValue: 'users_registered' },
    { name: 'Количество серверов', value: 'server_count', dailyValue: 'servers_created' },
    { name: 'Количество проектов', value: 'project_count', dailyValue: 'projects_created' },
  ];
  type = this.rows[0];
  minDate: Date = new Date(2020, 10, 9);
  dates: { in: Date, out: Date } = { in: null, out: null };
  activeLimit: string = 'month';

  get labels() { return this.stats && this.stats.daily && this.stats.daily.length > 0 ? this.stats.daily.map(it => it.date != null ? it.date : null) : [] }
  get lineChartData() { return this.stats && this.stats.daily && this.stats.daily.length > 0 ? this.stats.daily.map(it => it[this.type.dailyValue] != null ? it[this.type.dailyValue] : 0) : [] }

  @Watch('dates', { deep: true }) onDatesChange() {
    this.loadStats();
  }

  setDates(dates: { in: Date, out: Date }) {
    this.dates = dates;
  }

  setYearDates() {
    const end = new Date();
    const yearStart = startOfYear(end);
    const start = isAfter(this.minDate, yearStart) ? this.minDate : yearStart;
    this.setDates({in: start, out: end});
  }

  select(item) { this.type = item; }

  async loadStats() {
    this.isPageLoading = true;

    const end = this.dates && this.dates.out ? this.dates.out : new Date();
    const start = this.dates && this.dates.in ? this.dates.in : subBusinessDays(end, (this.activeLimit === 'month' ? 30 : this.activeLimit === 'week' ? 7 : this.activeLimit === 'year' ? 365 : 1 ));
    try {

      const resp = await getStats((isAfter(this.minDate, start) ? this.minDate : start).formatByPattern('yyyy-MM-dd'), end.formatByPattern('yyyy-MM-dd'));
      this.stats = resp.data;
      
    } catch (error) {
      this.error = error.response ? error.response : 'Не удалось загрузить данные';
    } finally {
      this.isPageLoading = false;
    }
  }

  async mounted() {
    this.loadStats();
  }
}
