import { QuickFilters } from '@/types';
import { jsonClient } from '../config';

export function getQuickFiltersGrouped() {
  return jsonClient.get<QuickFilters.QuickFiltersGroupedResponse[]>(`/admin/quick-filters/grouped/`)
}

export function createQuickFilter(tab: QuickFilters.Tabs, score: number, version?: number, tag?: number, has_one?: QuickFilters.HasOne) {
  return jsonClient.post<QuickFilters.QuickFilterItem>(`/admin/quick-filters/`, { tab, score, version, tag, has_one })
}

export function updateQuickFilter(id: string | number, tab?: QuickFilters.Tabs, score?: number, version?: number, tag?: number, has_one?: QuickFilters.HasOne) {
  return jsonClient.patch<QuickFilters.QuickFilterItem>(`/admin/quick-filters/${id}/`, { tab, score, version, tag, has_one })
}

export function deleteQuickFilter(id: string | number) {
  return jsonClient.delete<any>(`/admin/quick-filters/${id}/`)
}