
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Line } from 'vue-chartjs';

@Component({
  extends: Line
})
export default class LineChartComponent extends Vue {
  @Prop({ type: Array || Object }) chartData: any;
  @Prop({ type: Array }) chartLabels: any[];
  @Prop({ type: String, default: '#007aff' }) color: string;

  chart: any = null;
  customTooltips = (tooltip) => {
    // Tooltip Element
			var tooltipEl = document.getElementById('chartjs-tooltip');

			if (!tooltipEl) {
				tooltipEl = document.createElement('div');
				tooltipEl.id = 'chartjs-tooltip';
				document.body.appendChild(tooltipEl);
			}

			// Hide if no tooltip
			if (tooltip.opacity === 0) {
				tooltipEl.style.opacity = '0';
				return;
			}

			// Set caret Position
			tooltipEl.classList.remove('above', 'below', 'no-transform', 'right', 'left', 'center');
			if (tooltip.yAlign || tooltip.xAlign) {
        if (tooltip.yAlign) tooltipEl.classList.add(tooltip.yAlign === 'center' ? 'middle' : tooltip.yAlign);
        if (tooltip.xAlign) tooltipEl.classList.add(tooltip.xAlign);
			} else {
				tooltipEl.classList.add('no-transform');
			}

			function getBody(bodyItem) {
				return bodyItem.lines;
			}

      // Set Text
			if (tooltip.body) {
				var titleLines = tooltip.title || [];
				var bodyLines = tooltip.body[0].lines;
				tooltipEl.innerHTML = `<p><span style="color: #000">${parseInt(bodyLines[0])}</span>, ${titleLines[0] != null ? new Date(titleLines[0]).formatDateTime() : ''}</p><div></div>`;
			}

      var position = (this.$refs.canvas as HTMLCanvasElement).getBoundingClientRect();
			
			tooltipEl.style.opacity = '1';
			tooltipEl.style.left = position.left + tooltip.caretX + 'px';
			tooltipEl.style.top = position.top + window.pageYOffset + tooltip.caretY + 'px';
  }
  options = {
    showScale: true,
    responsive: true,
    scales: {
      yAxes: [{
        fontColor: 'rgba(0, 0, 0, 0.54)',
        fontSize: 10,
        ticks: {
          min: 0,
          maxTicksLimit: 4,
          padding: 6,
        },
        gridLines: {
          display: true,
          color: '#e5e5e5'
        }
      }],
      xAxes: [{
        fontColor: 'rgba(0, 0, 0, 0.54)',
        fontSize: 10,
        lineHeight: 1.6,
        ticks: {
          autoSkipPadding: 36,
          maxRotation: 0,
          minRotation: 0,
          padding: 6,
          callback: (value, index, values) => {
            return value ? new Date(value).formatByPattern('dd MMM') : '';
          }
        },
        gridLines: {
          drawOnChartArea: false,
          color: '#e5e5e5'
        },
      }]
    },
    hover: {
      mode: 'index',
      intersect: false
    },
    tooltips: {
      enabled: false,
      intersect: false,
      mode: 'index',
      position: 'nearest',
      custom: this.customTooltips
    },
    legend: {
      display: false
    },
    maintainAspectRatio: false
  }

  @Watch('chartData', { deep: true }) onChartDataChange() { this.init(); }
  @Watch('chartLabels', { deep: true }) onChartLabelsChange() { this.init(); }

  async init() {
    await (this as any).renderChart({
      labels: this.chartLabels,
      datasets: [
        {
          borderColor: this.color,
          pointBackgroundColor: 'rgba(0,0,0,0)',
          pointBorderColor: 'rgba(0,0,0,0)',
          pointHoverBorderColor: this.color,
          pointHoverBackgroundColor: '#fff',
          pointHoverRadius: 4,
          pointHitRadius: 10,
          pointHoverBorderWidth: 1,
          borderWidth: 1,
          backgroundColor: 'transparent',
          lineTension: 0,
          data: this.chartData
        }
      ]
    }, this.options)
  }

  mounted() {
    this.init();
  }
}
