import Vue, { VueConstructor }  from 'vue';
import { Component } from 'vue-property-decorator'
import vClickOutside from 'vue-click-outside';
import Tooltip from 'vue-directive-tooltip';
import VueMeta from 'vue-meta'

import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import '@/assets/css/reset.css';
import '@/assets/css/flexboxgrid.css';
import '@/assets/css/common.css';
import '@/assets/css/wysiwyg.css';
import "@/extensions/date";

Vue.directive('ClickOutside', vClickOutside);
Vue.use(Tooltip, {
  delay: 100,
  placement: 'top',
  class: 'b-custom-tooltip regular'
});
Vue.use(VueMeta)

Component.registerHooks([
  'metaInfo'
])

export default {
  install(Vue: VueConstructor, options: any) {}
}