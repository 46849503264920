import { UI } from './ui';

export namespace User {

  export interface LoginResponse {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    scope: string;
    token_type: string;
  }

  export interface UsersListResponse {
    count: number;
    results: Profile[];
  }

  export interface Profile {
    id: number;
    username: string;
    avatar: string;
    default_avatar?: boolean;
    email: string;
    email_state: EmailStatus;
    has_password?: boolean;
    is_server_owner?: boolean;
    new_email?: string;
    account_verified?: boolean;
    date_joined?: Date;
    role: UserRole;
    social_auth?: UserSocialAuth[];
    online: boolean;
    is_banned: boolean;
    last_action?: Date;
    is_ability_to_delete_projects?: boolean;
    is_ability_to_edit_projects?: boolean;
    is_ability_to_transfer?: boolean;
    is_ability_to_delete_comments?: boolean;
  }

  export interface UserLogsResponse {
    count: number;
    results: UserLogItem[];
  }

  export enum EmailStatus {
    VERIFIED = 'verified',
    CONFIRM = 'confirm',
    CHANGE = 'change'
  }

  export enum UserRole {
    user = 'Пользователь',
    admin = 'Администратор',
    moder = 'Модератор',
    senior_moder = 'Старший модератор'
  }

  export interface UserSocialAuth { provider: SocialProvider; }

  export enum SocialProvider {
    FACEBOOK = 'facebook',
    GOOGLE = 'google-oauth2',
    VK = 'vk-oauth2',
    OK = 'odnoklassniki-oauth2'
  }

  export enum SocialProviderRU {
    'facebook' = 'Facebook',
    'google-oauth2' = 'Google',
    'vk-oauth2' = 'Вконтакте',
    'odnoklassniki-oauth2' = 'Одноклассники'
  }
  
  export class SignInForm extends UI.Form {
    username: UI.FormField = new UI.FormField();
    password: UI.FormField = new UI.FormField();

    constructor() {
      super();
    }

    isValid(): boolean {
      if (!this.username.isValid()) return false;
      if (!this.password.isValid()) return false;
      return true;
    }

    reset() {
      this.username.reset();
      this.password.reset();
      this.resetForm();
    }
  }

  export class ModerForm extends UI.Form {
    user: Profile;
    userError: string = '';
    role: UI.FormField;

    constructor(user?: Profile) {
      super();
      this.user = user ? user : null;
      this.role = new UI.FormField(user ? user.role : '')
    }

    isValid(): boolean {
      if (!this.user) {
        this.userError = 'Выберите пользователя'
        return false;
      }
      if (!this.role.isValid()) return false;
      return true;
    }

    resetErrors() {
      this.userError = '';
      this.role.error = '';
      this.error = '';
    }
  }

  export enum UserModerationAction {
    BAN = 'ban',
    UNBAN = 'unban',
  }

  export enum UserBanReason {
    obscene_language = 'obscene_language',
    fraud = 'fraud',
    spam = 'spam',
    other = 'other'
  }

  export interface UserLogItem {
    id: number;
    action: UserModerationAction;
    actor: User.Profile;
    reason: UserBanReason;
    details: string;
    timestamp: Date;
    user: number;
  }
}