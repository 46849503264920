
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { isBeforeDate, isBetweenDates, monthList } from './util/dateUtils';
import parse from 'date-fns/parse';
import isSameDay from 'date-fns/isSameDay';
import setMonth from 'date-fns/setMonth';
import getMonth from 'date-fns/getMonth';
import setDate from 'date-fns/setDate';
import getDate from 'date-fns/getDate';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
// import differenceInDays from 'date-fns/difference_in_days';
import DatepickerInput from './components/DatepickerInput.vue';
import CalendarDialog from './components/CalendarDialog.vue';

@Component({
  components: { 
    DatepickerInput,
    CalendarDialog
  }
})
export default class DatepickerComponent extends Vue {
  @Prop() value: any;
  @Prop({ type: Date }) minDate: Date;
  @Prop({ type: Date }) maxDate: Date;
  @Prop({ type: Date }) inDateProp: Date;
  @Prop({ type: Date }) outDateProp: Date;
  
  opened: boolean = false;
  inDate: Date = null;
  outDate: Date = null;
  inDateFormatted: Date = null;
  outDateFormatted: Date = null;
  inDateSelected: Date = null;
  outDateSelected: Date = null;
  activeInput: string = 'checkin';
  displayValue: string = '';
  // timeout: any = undefined;
  dates = [
    { display: 'Год', value: 'year' },
    { display: 'Месяц', value: 'month' },
    { display: 'Неделя', value: 'week' }
  ]

  @Watch('inDate') inDateWatcher(value) {
    this.inDateFormatted = value;
    this.onDoneClicked();
  }
  @Watch('outDate') outDateWatcher(value) {
    this.outDateFormatted = value;
    this.onDoneClicked();
  }
  
  get dynamicMinDate() {
    if (this.inDateFormatted && this.activeInput === 'checkout') {
      return new Date(this.inDateFormatted);
    } else return new Date(this.minDate);
  }
  
  setActive(active: string) {
    if (!this.inDateFormatted && active === 'checkout') return;
    this.activeInput = active;
  }

  handleOpenDialog(opened, isRouteChanged = false) {
    if (opened) {
      document.body.classList.add('hidden');
      // this.inDateFormatted = this.inDate;
      // this.outDateFormatted = this.outDate;
    } else {
      document.body.classList.remove('hidden');
    }
    this.opened = opened;
  }

  closeDatepicker() {
    if (this.opened) {
      document.body.classList.remove('hidden');
      this.opened = false;
    }
  }

  onClickOutside() {
    if (this.opened) {
      if (!this.inDateFormatted) {
        // this.inDateFormatted = this.inDate;
        // this.outDateFormatted = this.outDate;
        this.handleOpenDialog(false);
      } else this.onDoneClicked();
    }
  }

  async onDoneClicked() {
    const self = this;
    if (!self.inDateFormatted) return false;
    else {
      if (!self.outDateFormatted) {
        self.outDateFormatted = new Date(self.inDateFormatted);
        self.outDateFormatted.setDate(self.inDateFormatted.getDate()+1);
      }
      self.inDateSelected = self.inDateFormatted;
      self.outDateSelected = self.outDateFormatted;
    }
    await this.handleOpenDialog(false);
    await self.$emit('setDates', { in: self.inDateFormatted, out: self.outDateFormatted });
    this.$emit('input', '');
    this.displayValue = `${self.inDateFormatted.getDate()} ${monthList[self.inDateFormatted.getMonth()]} - ${self.outDateFormatted.getDate()} ${monthList[self.outDateFormatted.getMonth()]}`
  }

  updateDates() {
    const isStringDate = this.dates.find(it => it.value === this.value);
    if (isStringDate) {
      this.displayValue = isStringDate.display;
      const today = new Date();
      this.outDateFormatted = today;
      this.outDateSelected = today;

      if (isStringDate.value === 'year') {
        const yearAgo = new Date().setFullYear(today.getFullYear() - 1);
        const inD = isBefore(yearAgo, this.minDate) ? this.minDate : yearAgo;
        this.inDateFormatted = new Date(inD);
        this.inDateSelected = new Date(inD);
      } else if (isStringDate.value === 'month') {
        const monthAgo = new Date().setMonth(today.getMonth() - 1);
        this.inDateFormatted = new Date(monthAgo);
        this.inDateSelected = new Date(monthAgo);
      } else if (isStringDate.value === 'week') {
        const weekAgo = new Date().setDate(today.getDate() - 7);
        this.inDateFormatted = new Date(weekAgo);
        this.inDateSelected = new Date(weekAgo);
      } else  {
        console.log('error')
      }
    } else {
      this.displayValue = `${this.inDateProp.getDate()} ${monthList[this.inDateProp.getMonth()]} - ${this.outDateProp.getDate()} ${monthList[this.outDateProp.getMonth()]}`
    }
    // this.inDateFormatted = this.inDate;
    // this.outDateFormatted = this.outDate;
    // this.inDateSelected = this.inDate;
    // this.outDateSelected = this.outDate;
  }

  incrCheckin(isNextDate: boolean, submit: boolean) {
    if (this.inDateFormatted) {
      let _inD_ = new Date(this.inDateFormatted),
          _outD_ = new Date(this.outDateFormatted);
      
      const inD = new Date(_inD_.setDate(_inD_.getDate()+(isNextDate ? 1 : -1))),
            outD = isNextDate && isSameDay(inD, _outD_) ? new Date(_outD_.setDate(_outD_.getDate()+1)) : _outD_,
            prevMont = new Date(setMonth(_outD_, getMonth(_outD_) - 1)),
            isMoreThanMonth = !isNextDate ? isSameDay(inD, prevMont) : false;

      if (isMoreThanMonth) return;
      if (!isNextDate && !isBetweenDates(inD, this.minDate, this.maxDate) && !isSameDay(inD, this.minDate)) return;
      if (isNextDate && !isBetweenDates(outD, this.minDate, this.maxDate) && !isSameDay(outD, this.maxDate)) return;
      
      if (submit && !this.opened) {
        this.$emit('setDates', { in: new Date(inD), out: new Date(outD) });
      } else {
        this.inDateFormatted = new Date(inD)
        this.inDateSelected = new Date(inD)
        if (this.outDateFormatted) {
          this.outDateFormatted = new Date(outD);
          this.outDateSelected = new Date(outD);
        }
      }
    }
  }
  incrCheckout(isNextDate: boolean, submit: boolean) {
    if (this.outDateFormatted) {
      let _inD_ = new Date(this.inDateFormatted),
          _outD_ = new Date(this.outDateFormatted);

      const outD = new Date(_outD_.setDate(_outD_.getDate()+(isNextDate ? 1 : -1))),
            inD = !isNextDate && isSameDay(_inD_, outD) ? new Date(_inD_.setDate(_inD_.getDate()-1)) : _inD_,
            nextMonth = new Date(setMonth(_inD_, getMonth(_inD_) + 1)),
            isMoreThanMonth = isNextDate ? isAfter(outD, nextMonth) : false;
       
      if (isMoreThanMonth) return;
      if (!isNextDate && !isBetweenDates(inD, this.minDate, this.maxDate) && !isSameDay(inD, this.minDate)) return;
      if (isNextDate && !isBetweenDates(outD, this.minDate, this.maxDate) && !isSameDay(outD, this.maxDate)) return;

      if (submit && !this.opened) {
        this.$emit('setDates', { in: new Date(inD), out: new Date(outD) });
      } else {
        this.inDateFormatted = new Date(inD)
        this.outDateFormatted = new Date(outD)
        this.inDateSelected = new Date(inD)
        this.outDateSelected = new Date(outD)
      }
    }
  }

  mounted() {
    const self = this;
    this.updateDates();

    this.$on('incrCheckin', (isNextDate: boolean, submit: boolean = true) => { this.incrCheckin(isNextDate, submit) })
    this.$on('incrCheckout', (isNextDate: boolean, submit: boolean = true) => { this.incrCheckout(isNextDate, submit) })

    this.$on('updateSelected', (selectedDay) => {

      if (this.activeInput === 'checkin') {
        this.inDateFormatted = selectedDay;
        this.outDateFormatted = undefined;
        this.setActive('checkout');
      } else {
        this.outDateFormatted = selectedDay;
        this.setActive('checkin');
      }

    });

    this.$on('on-this-day', (selected) => {
      this.onDoneClicked();
    });

    this.$on('set-limit', (limit) => {
      const stringLimit = this.dates.find(it => it.value === limit);
      this.displayValue = stringLimit.display;
      const today = new Date();
      if (limit === 'year') {
        const yearAgo = new Date().setFullYear(today.getFullYear() - 1);
        const inD = isBefore(yearAgo, this.minDate) ? this.minDate : yearAgo;
        this.$emit('setDates', { in: new Date(inD), out: today });
      } else if (limit === 'month') {
        const monthAgo = new Date().setMonth(today.getMonth() - 1);
        this.$emit('setDates', { in: new Date(monthAgo), out: today });
      } else if (limit === 'week') {
        const weekAgo = new Date().setDate(today.getDate() - 7);
        this.$emit('setDates', { in: new Date(weekAgo), out: today });
      }
      this.$emit('input', limit);
      this.handleOpenDialog(false);
    });

    this.$on('clear-dates', () => {
      this.setActive('checkin');
      this.inDateFormatted = undefined;
      this.outDateFormatted = undefined;
    })
  }
}
