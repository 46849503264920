import Router, { RouterOptions } from 'vue-router';
import store from '@/store';
import { get as getCookie } from 'es-cookie';
import { routes } from './routes';
import { roots } from '@/utils';

export const options: RouterOptions = {
  routes,
  base: '/',
  mode: 'history',
  fallback: true,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 }
  },
};

export function guard(router: Router): Router {
  router.beforeEach(async (to, from, next) => {
    if (!to.name) return next('/');

    const token = getCookie('auth.token');
    if (!token && !to.fullPath.includes('auth')) return next('/auth');
    
    if (token && (!store.state.user || !store.state.user.user)) {
      await Promise.all([
        store.dispatch('user/fetchUser'),
        store.dispatch('init')
      ]).catch(async (error) => await store.dispatch('user/logout'))
    }

    if (to.fullPath.includes('auth') && !!token) return next('/');
    else if (store.state.user && store.state.user.user && store.state.user.user.role != 'admin' && !roots[store.state.user.user.role].includes(to.name)) return next({ name: roots[store.state.user.user.role][0] });
    // else if (store.state.user && store.state.user.user && store.state.user.user.role === Mod.ModRole[Mod.ModRole.Moderator] && (to.fullPath.includes('statistics') || to.fullPath.includes('managers'))) return next('/');
    else return next();
  });

  return router;
}