
import { Component, Vue, Prop } from 'vue-property-decorator';
import WeekRow from './WeekRow.vue';
import { getWeekArray } from '../util/dateUtils';

@Component({
  components: { WeekRow }
})
export default class CalendarMonthComponent extends Vue {
  @Prop({ type: Date }) inDate: Date;
  @Prop({ type: Date }) outDate: Date;
  @Prop({ type: Date }) minDate: Date;
  @Prop({ type: Date }) maxDate: Date;
  @Prop({ type: Date }) displayDate: Date;
  @Prop({ type: Date }) selectedDate: Date;
  @Prop({ type: Number }) firstDayOfWeek: number;
  
  get weekArray() { return getWeekArray(this.displayDate, this.firstDayOfWeek); }
}
