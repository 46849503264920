import Vue from 'vue';
import Vuex, { MutationTree, ActionTree, ActionContext } from 'vuex';
import { Project } from '@/types';
import { getTags, getVersions, updateTag } from '@/api/services/servers';
import { user } from './modules/user';
import cloneDeep from 'lodash/cloneDeep';

Vue.use(Vuex)

export class State {
  versions: Project.VersionsResponse = null;
  tags: Project.TagsResponse = null;
}

export const getters = {
  getFilteredTags(state) {
    const autoTags = ["recent-wipe", "massive-online", "little-online", "has-launcher", "no-launcher"];
    let a = cloneDeep(state.tags);
    a.main.items = a.main.items.filter(it => !autoTags.includes(it.value));
    return a;
  },
}

const mutations = <MutationTree<State>> {
  setVersions(state: State, versions: Project.VersionsResponse) { state.versions = versions; },
  setTags(state: State, tags: Project.TagsResponse) { state.tags = tags; }
}

const actions = <ActionTree<State, any>> {
  async init(store: ActionContext<State, any>) {
    await Promise.all([getTags(), getVersions()])
      .then(res => {
        store.commit('setTags', res[0].data);
        store.commit('setVersions', new Project.VersionsResponse(res[1].data));
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  async updateVersionsList(store: ActionContext<State, any>) {
    await getVersions()
      .then(res => {
        store.commit('setVersions', new Project.VersionsResponse(res.data));
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  async updateTagsList(store: ActionContext<State, any>) {
    await getTags()
      .then(res => {
        store.commit('setTags', res.data);
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}

export default new Vuex.Store({
  state: new State(),
  getters,
  mutations,
  actions,
  modules: {
    user
  }
})
