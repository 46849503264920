import { UI } from './ui';
import { Project } from './project';

export namespace QuickFilters {
  export class QuickFiltersGroupedResponse {
    tab: Tabs;
    quick_filters: QuickFilterItem[];

    constructor(group: QuickFiltersGroupedResponse, versions: Project.Version[], tags: Project.Tag[], has_one_list: UI.DropdownItem[]) {
      this.tab = group.tab;
      this.quick_filters = group.quick_filters.map(it => new QuickFilterItem(it, versions, tags, has_one_list));
    }
  }

  export class QuickFilterItem {
    id: number;
    tab: Tabs;
    score: number;
    version: number;
    tag: number;
    has_one: HasOne;
    version_model: Project.Version = null;
    tag_model: Project.Tag = null;
    has_one_model: UI.DropdownItem = null;

    constructor(item: QuickFilterItem, versions?: Project.Version[], tags?: Project.Tag[], has_one_list?: UI.DropdownItem[]) {
      this.id = item.id;
      this.tab = item.tab;
      this.score = item.score;
      this.version = item.version || null;
      this.tag = item.tag || null;
      this.has_one = item.has_one || null;
      if (item.version && versions && versions.length > 0) {
        this.version_model = versions.find(it => it.id === item.version);
      }
      if (item.tag && tags && tags.length > 0) {
        this.tag_model = tags.find(it => it.id === item.tag);
      }
      if (item.has_one && has_one_list && has_one_list.length > 0) {
        this.has_one_model = has_one_list.find(it => it.value === item.has_one);
      }
    }
  }

  export enum Tabs {
    ALL = 'all',
    NEW = 'new',
    PC = 'pc',
    PC_MOD = 'pc mod',
    BEDROCK = 'bedrock'
  }

  export enum HasOne {
    MINI = 'mini',
    MOD = 'mod',
    PLUGIN = 'plugin'
  }

  export class QuickFilterForm extends UI.Form {
    id: number = null;
    tab: UI.DropdownItem = null;
    tab_error: string = '';
    score: UI.FormField = new UI.FormField();
    version: Project.Version = null;
    version_error: string = '';
    tag: Project.Tag = null;
    tag_error: string= '';
    has_one: UI.DropdownItem = null;
    has_one_error: string = '';

    constructor(filter?: QuickFilterItem, tab?: UI.DropdownItem) {
      super();
      this.id = filter ? filter.id : null;
      this.tab = tab || null;
      this.score = new UI.FormField(filter && filter.score != null ? String(filter.score) : '');
      this.version = filter && filter.version_model ? filter.version_model : null;
      this.tag = filter && filter.tag_model ? filter.tag_model : null;
      this.has_one = filter && filter.has_one_model ? filter.has_one_model : null;
    }

    isValid(): boolean {
      if (this.tab === null) {
        this.tab_error = 'Выберите вкладку';
        return false
      }
      if (!this.score.isValid()) return false;
      if (this.version === null && this.tag === null && this.has_one === null) {
        this.has_one_error = 'Выберите версию, категорию или обобщающий фильтр';
        return false
      }
      if (this.version != null && this.tag != null && this.has_one != null) {
        this.has_one_error = 'Выберите только один из фильтров';
        return false
      }
      return true;
    }

    resetErrors() {
      this.error = '';
      this.tab_error = '';
      this.score.error = '';
      this.version_error = '';
      this.tag_error = '';
      this.has_one_error = '';
    }
  }
}