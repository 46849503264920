import { Project, Common } from '@/types';
import { jsonClient } from '../config';

export function getServers(offset?: number, limit?: number, name?: string, state?: Project.ServerState) {
  return jsonClient.get<Project.ServersListResponse>('/admin/servers/', { params: { offset, limit, name: name || undefined, state } })
}

export function getServerByID(id: string | number) {
  return jsonClient.get<Project.Server>(`/admin/servers/${id}/`)
}

export function getServerLogs(id: string | number, offset?: number, limit?: number) {
  return jsonClient.get<Project.ServerLogsResponse>(`/admin/servers/${id}/log/`, { params: { offset, limit } })
}

export function moderateServer(id: string | number, action: Project.ModerationAction, reason?: Common.BanReason, details?: string) {
  return jsonClient.post<Project.Server>(`/admin/servers/${id}/moderate/`, { action, reason, details })
}

export function getProjects(offset?: number, limit?: number, search?: string, state?: Project.ProjectState) {
  return jsonClient.get<Project.ProjectsListResponse>('/admin/projects/', { params: { offset, limit, search, state } })
}

export function getProjectByID(id: string | number) {
  return jsonClient.get<Project.Project>(`/admin/projects/${id}/`)
}

export function getProjectLogs(id: string | number, offset?: number, limit?: number) {
  return jsonClient.get<Project.ProjectLogsResponse>(`/admin/projects/${id}/log/`, { params: { offset, limit } })
}

export function deleteProjectPenalty(id: string | number) {
  return jsonClient.delete<any>(`/admin/project-penalty/${id}/`)
}

export function moderateProject(id: string | number, action: Project.ModerationAction, reason?: Common.BanReason, details?: string, penalty_points?: number, penalty_expires?: Date) {
  return jsonClient.post<Project.Project>(`/admin/projects/${id}/moderate/`, { action, reason, details, penalty_points, penalty_expires })
}

export function deleteProject(id: string | number) {
  return jsonClient.post<Project.Project>(`/admin/projects/${id}/delete/`)
}

export function updateProject(id: string | number, formdata) {
  return jsonClient.patch<Project.Project>(`/servers/projects/${id}/`, formdata)
}

export function getTags() {
  return jsonClient.get<Project.TagsResponse>('/servers/tags/')
}

export function createTag(name: string, group: Project.TagGroupType, value: string, description: string) {
  return jsonClient.post<any>('/servers/tags/', { name, group, value, description })
}

export function updateTag(id: string | number, name?: string, group?: string, value?: string, description?: string) {
  return jsonClient.patch<any>(`/servers/tags/${id}/`, { name, group, value, description })
}

export function deleteTag(id: string | number) {
  return jsonClient.delete<any>(`/servers/tags/${id}/`)
}

export function getVersions() {
  return jsonClient.get<Project.VersionsResponse>('/servers/versions/')
}

export function createVersion(major: string, minor: string, micro: string, type: string, is_generalizing?: boolean, is_emphasized?: boolean) {
  return jsonClient.post<Project.Version>('/servers/versions/', {
    major,
    minor,
    micro: micro || undefined,
    type,
    is_generalizing: is_generalizing || undefined,
    is_emphasized: is_emphasized || undefined
  })
}

export function updateVersion(id: string | number, major?: string, minor?: string, micro?: string, type?: string, is_generalizing?: boolean, is_emphasized?: boolean) {
  return jsonClient.patch<Project.Version>(`/servers/versions/${id}/`, {
    major,
    minor,
    micro: micro || null,
    type,
    is_generalizing: is_generalizing || undefined,
    is_emphasized: is_emphasized || undefined
  })
}

export function deleteVersion(id: string | number) {
  return jsonClient.delete<any>(`/servers/versions/${id}/`)
}

export function checkUrl(url: string) {
  return jsonClient.post('/servers/projects/check-url/', { url })
}

export function getBlackListPages() {
  return jsonClient.get<Project.BlacklistItem[]>('/servers/blacklist/')
}

export function createBlacklistPage(name: string, url: string) {
  return jsonClient.post<Project.BlacklistItem>('/servers/blacklist/', { name, url })
}

export function updateBlacklistPage(id: string | number, name?: string, url?: string) {
  return jsonClient.patch<Project.BlacklistItem>(`/servers/blacklist/${id}/`, { name, url })
}

export function deleteBlacklistPage(id: string | number) {
  return jsonClient.delete<any>(`/servers/blacklist/${id}/`)
}

export function getReportsGrouped(offset?: number, limit?: number, search?: string) {
  return jsonClient.get<Project.GetReportsGroupedResponse>(`/admin/reports-grouped/`, { params: { offset, limit, search } })
}

export function getProjectReports(project: string | number, reviewed?: boolean, offset?: number, limit?: number) {
  return jsonClient.get<Project.GetProjectReportsResponse>(`/admin/report/`, { params: { project, reviewed, offset, limit } })
}

export function reviewReport(report_ids: number[]) {
  return jsonClient.post<any>(`/admin/report/review/`, { report_ids })
}

export function transferProject(projectId: string, ownerId: number) {
  return jsonClient.post<any>(`/admin/projects/${projectId}/transfer/`, {
    id: ownerId,
  })
}

export function transferServer(serverId: string, ownerId: number) {
  return jsonClient.post<any>(`/admin/servers/${serverId}/transfer/`, {
    id: ownerId,
  })
}