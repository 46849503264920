import { UI, Common } from './ui';
import { User } from './user';

export namespace Comment {
  export interface CommentsListResponse {
    count: number;
    results: Comment[];
  }

  export interface CommentLogsResponse {
    count: number;
    results: CommentLogItem[];
  }

  export interface Comment {
    id: number;
    message: string;
    reply_to: number;
    user: User.Profile;
    timestamp: Date;
    parent: number;
    vote_sum: number;
    upvoted: boolean;
    downvoted: boolean;
    state: CommentState;
    nested?: Comment[];
    project_inner_url?: string;
  }

  export enum CommentState {
    moderation = 'moderation',
    ok = 'ok',
    banned = 'banned'
  }

  export enum CommentOrder {
    timestamp = 'timestamp',
    vote_sum = 'vote_sum'
  }

  export interface CommentLogItem {
    id: number;
    comment: Comment | number;
    action: LogActionType;
    actor: User.Profile;
    ban_details: Common.BanReason;
    ban_reason: string;
    timestamp: Date;
  }

  export enum LogActionType {
    accept = 'accept',
    ban = 'ban'
  }

  export class CommentForm extends UI.Form {
    message: UI.FormField = new UI.FormField();

    constructor() {
      super();
    }
  }
}