
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Project} from '@/types';
import ClipboardCopyComponent from '@/components/ClipboardCopyComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

@Component({
  components: {
    ClipboardCopyComponent,
    ButtonComponent
  }
})
export default class TableRowServerItemComponent extends Vue {
  @Prop({ type: Object }) server: Project.Server;
  @Prop({ type: String }) serverType: Project.ServerType;
  @Prop({ type: Number, default: null }) lobbyID: number;
  @Prop({ type: Number, default: 256 }) titleWidth: number;
  @Prop({ type: Boolean, default: true }) borderTop: boolean;
  @Prop({ type: Boolean, default: false }) isOpen: boolean;
  @Prop({ type: Object }) tags: Project.TagsResponse;
  @Prop({ type: Object }) versions: Project.VersionsResponse;

  baseURL: string = process.env.VUE_APP_FRONT_URL;
  baseDjangoURL: string = process.env.VUE_APP_DJANGO_BASE_URL;
  
  get versionsText() {
    const _serverType = this.serverType && this.serverType != Project.ServerType.PC_MOD ? this.serverType : Project.ServerType.PC;
    if (!this.versions || !this.server || !(this.server && this.server.versions && _serverType)) return null

    const list = this.versions[_serverType].filter(it => this.server.versions.includes(it.id));
    if (list.length > 0) {
      const start = list[0].value;
      const end = list.length > 1 ? list[list.length - 1].value : null;
      return `${_serverType.toUpperCase()} ${(end) ? `${end} - ` : ''}${start}`
    } else return ''
  }
  get shortDescription() {
    if (!this.tags) return null;

    const tagsList = Object.values(this.tags).reduce((prev, cur) => [...prev, ...cur.items], []);
    return this.server.tags
      .map(it => {
        const tag = tagsList.find(x => x.id === it);
        return tag && tag.name ? tag.name : undefined;
      })
      .filter(it => it != undefined)
      .join(', ')
  }

  toggleDescription() { this.$emit('toggle'); }
}
