
import { Component, Vue } from 'vue-property-decorator';
import { Project, Common } from '@/types'
import { getProjectReports } from '@/api/services/servers';
import ButtonComponent from '@/components/ButtonComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

@Component({
  components: {
    ButtonComponent,
    SpinnerComponent
  }
})
export default class ProjectViewedReportsPage extends Vue {
  isPageLoading: boolean = false;
  isLoadingMore: boolean = false;
  reportsList: Project.ReportItem[] = [];
  reportsCount: number = 0;

  getReportType(type: Common.BanReason) {
    switch (type) {
      case Common.BanReason.WRONG_CATEGORIES: return 'Неверные категории';
      case Common.BanReason.FRAUD: return 'Мошенничество';
      case Common.BanReason.SPAM: return 'Спам';
      case Common.BanReason.OBSCENE: return 'Нецензурная лексика';
      case Common.BanReason.OTHER: return 'Другое';
      default: return 'Другое';
    }
  }

  async loadMore() {
    const projectID = this.$route.params.id;
    this.isLoadingMore = true;
    try {
      const reports = await getProjectReports(projectID, true, this.reportsList.length, 10);
      this.reportsList.push(...reports.data.results);
      this.reportsCount = reports.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoadingMore = false;
    }
  }

  async mounted() {
    const projectID = this.$route.params.id;
    this.isPageLoading = true;
    try {
      const reports = await getProjectReports(projectID, true, 0, 10);
      this.reportsList = reports.data.results;
      this.reportsCount = reports.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
