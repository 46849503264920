
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getEditsList, reviewEdit } from '@/api/services/edits';
import { Edits } from '@/types';
import debounce from 'lodash/debounce';
import SearchComponent from '@/components/SearchComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

@Component({
  components: {
    SearchComponent,
    ButtonComponent,
    SpinnerComponent
  }
})
export default class FeedPage extends Vue {
  isPageLoading: boolean = true;
  isLoadingMore: boolean = false;
  query: string = this.$route.query && this.$route.query.q ? String(this.$route.query.q) : '';
  editsList: Edits.EditItem[] = [];
  editsCount: number = 0;
  onQueryChangeDebounced: Function = debounce(this.onQueryChange, 350);
  
  @Watch('query') queryWatcher(value: string) {
    this.isPageLoading = true;
    this.onQueryChangeDebounced();
  }

  async onQueryChange() {
    try {
      this.$router.push({ query: this.query ? { q: this.query } : null })
      const resp = await getEditsList(0, 10, this.query, false);
      this.editsList = resp.data.results;
      this.editsCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }

  feedContent(item: Edits.EditItem) {
    const type = item.server != null ? 'сервер' : item.project != null ? 'проект' : '';
    const link = `<a class="blue--text" href="${item.server != null ? '/servers/'+item.server : item.project != null ? '/projects/'+item.project : '#'}">${item.server ? item.server : item.project ? item.project : ''}</a>`
    const changeKeys = (key: string): {verb, key} => {
      switch (key) {
        case 'banner': return {verb: 'изменился', key: 'основной баннер'};
        case 'banner2': return {verb: 'изменился', key: 'большой баннер'};
        case 'name': return {verb: 'изменилось', key: 'название'};
        case 'inner_url': return {verb: 'изменился', key: 'URL адрес'};
        case 'motto': return {verb: 'изменился', key: 'слоган'};
        case 'description': return {verb: 'изменилось', key: 'описание'};
        case 'launcher_link': return {verb: 'изменилась', key: 'ссылка на скачивание лаунчера'};
        case 'project_site_link': return {verb: 'изменилась', key: 'ссылка на сайт'};
        case 'vk_link': return {verb: 'изменилась', key: 'ссылка ВКонтакте'};
        case 'video_link': return {verb: 'изменилось', key: 'видео'};
        case 'encouragement': return {verb: 'изменилась', key: 'система поощрений'};
        case 'encouragement_link': return {verb: 'изменилась', key: 'ссылка на систему поощрений'};
        case 'lobby': return {verb: 'изменилось', key: 'лобби'};
        case 'type': return {verb: 'изменился', key: 'тип сервера'};
        case 'screenshots': return {verb: 'изменились', key: 'скриншоты'};
        case 'tags': return {verb: 'изменились', key: 'категории'};
        case 'versions': return {verb: 'изменились', key: 'версии'};
      
        default: return {verb: 'изменился', key}
      }
    }
    const change = () => {
      const keysList = Object.keys(item.edits).map(it => changeKeys(it));
      if (Object.keys(item.edits).length > 1) {
        return `изменились ${keysList.map(it => it.key).join(', ')}`
      } else {
        return keysList[0] && `${keysList[0].verb} ${keysList[0].key}`
      }
    }
    
    
    return `На ${type}е ${link} ${change()}.`
  }

  async setReviewed(id: number) {
    try {
      const resp = await reviewEdit(id);
      const reviewed = this.editsList.find(it => it.id === id);
      if (reviewed) reviewed.reviewed = true;
    } catch (error) {
      console.log(error)
    }
  }

  async loadMore() {
    this.isLoadingMore = true;
    try {
      const resp = await getEditsList(this.editsList.length, 10, this.query, false);
      this.editsList.push(...resp.data.results);
      this.editsCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoadingMore = false;
    }
  }

  async mounted() {
    const q = this.$route.query && this.$route.query.q ? String(this.$route.query.q) : '';
    this.query = q;
    this.isPageLoading = true;
    try {
      const resp = await getEditsList(0, 10, q, false);
      this.editsList = resp.data.results;
      this.editsCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
