
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getProjectLogs, deleteProjectPenalty } from '@/api/services/servers';
import { Project, Common, UI } from '@/types';
import { errorHandler } from '@/utils';
import TextModalComponent from '@/components/TextModalComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

@Component({
  components: {
    TextModalComponent,
    ButtonComponent
  }
})
export default class ProjectHistoryPage extends Vue {
  @Prop({ type: Object }) project: Project.Project;

  isPageLoading: boolean = true;
  logList: Project.ProjectLogItem[] = [];
  logsCount: number = 0;
  error: string = '';
  isDeleteModalShown: boolean = false;
  deleteForm: UI.Form = new UI.Form();
  seletedPenalty: Project.ProjectLogItem = null;
  isLoadingMore: boolean = false;

  getActionClass(action: Project.ModerationAction) {
    switch (action) {
      case Project.ModerationAction.APPROVE:
      case Project.ModerationAction.UNBAN: return 'green--text';
      case Project.ModerationAction.PENALIZE:
      case Project.ModerationAction.BAN: return 'red--text';
      default: return 'black--text';
    }
  }

  getActionText(action: Project.ModerationAction) {
    switch (action) {
      case Project.ModerationAction.APPROVE: return 'Принят';
      case Project.ModerationAction.DECLINE: return 'Отклонен';
      case Project.ModerationAction.BAN: return 'Заблокирован';
      case Project.ModerationAction.UNBAN: return 'Разблокирован';
      case Project.ModerationAction.PENALIZE: return 'Оштрафован';
      default: return '';
    }
  }

  getBanText(reason: Common.BanReason) {
    switch (reason) {
      case Common.BanReason.FRAUD: return 'Мошенничество';
      case Common.BanReason.WRONG_CATEGORIES: return 'Неверные категории';
      case Common.BanReason.SPAM: return 'Спам';
      case Common.BanReason.OBSCENE: return 'Нецензурная лексика';
      case Common.BanReason.OTHER: return 'Другое';
      default: return ''
    }
  }

  showDeletePenaltyModal(item: Project.ProjectLogItem) {
    this.seletedPenalty = item;
    this.isDeleteModalShown = true;
  }

  async deletePenalty() {
    this.deleteForm.isLoading = true;
    try {
      await deleteProjectPenalty(this.seletedPenalty.id);
      const index = this.logList.findIndex(it => it.id === this.seletedPenalty.id);
      if (index >= 0) {
        this.logList.splice(index, 1);
        this.logsCount--;
      }
      this.isDeleteModalShown = false;
      this.$nextTick(() => {
        this.seletedPenalty = null;
        this.deleteForm = new UI.Form();
      })
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.deleteForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.deleteForm.isLoading = false;
    }
  }

  async mounted() {
    const serverID = this.$route.params.id;
    this.isPageLoading = true;
    try {
      const resp = await getProjectLogs(serverID, 0, 10);
      this.logList = resp.data.results;
      this.logsCount = resp.data.count;
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.error = errorHandler(it, errResponse[it][0])
        console.log(errResponse)
      })
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
