
import { Component, Vue } from 'vue-property-decorator';
import { Project } from '@/types';
import { getBlackListPages, createBlacklistPage, updateBlacklistPage, deleteBlacklistPage } from '@/api/services/servers';
import { errorHandler } from '@/utils';
import SearchComponent from '@/components/SearchComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import InputComponent from '@/components/InputComponent.vue';

@Component({
  metaInfo: {
    title: 'Страницы Blacklist'
  },
  components: {
    SearchComponent,
    SpinnerComponent,
    ButtonComponent,
    ModalComponent,
    InputComponent
  }
})
export default class BlacklistPage extends Vue {
  isPageLoading: boolean = true;
  query: string = '';
  isAddModalShown: boolean = false;
  isEditModalShown: boolean = false;
  isDeleteModalShown: boolean = false;
  selectedPage: Project.BlacklistForm = new Project.BlacklistForm();
  blacklistpages: Project.BlacklistItem[] = [];

  get filteredBlacklist() {
    const filterFunc = (it: Project.BlacklistItem) => {
      return it.name.toLowerCase().includes(this.query.toLowerCase()) || it.url.toLowerCase().includes(this.query.toLowerCase())
    }
    return this.query.length > 0 ? this.blacklistpages.filter(it => filterFunc(it)) : this.blacklistpages;
  }

  openAddModal() {
    this.selectedPage = new Project.BlacklistForm();
    this.$nextTick(() => {
      this.isAddModalShown = true;
    })
  }
  async addBlacklist() {
    this.selectedPage.isLoading = true;
    try {
      const blacklistItem = await createBlacklistPage(this.selectedPage.name.value, this.selectedPage.url.value);
      this.blacklistpages.push(blacklistItem.data)
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (this.selectedPage[it]) this.selectedPage[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedPage.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedPage.isLoading = false;
    }
  }

  openEditModal(page: Project.BlacklistItem) {
    this.selectedPage = new Project.BlacklistForm(page);
    this.$nextTick(() => {
      this.isEditModalShown = true;
    })
  }
  async editBlacklist() {
    this.selectedPage.isLoading = true;
    try {
      const blacklistItem = await updateBlacklistPage(this.selectedPage.id, this.selectedPage.name.value, this.selectedPage.url.value);
      let edited = this.blacklistpages.find(it => it.id === this.selectedPage.id);
      edited.name = blacklistItem.data.name;
      edited.url = blacklistItem.data.url;
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (this.selectedPage[it]) this.selectedPage[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedPage.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedPage.isLoading = false;
    }
  }

  openDeleteModal(page: Project.BlacklistItem) {
    this.selectedPage = new Project.BlacklistForm(page);
    this.$nextTick(() => {
      this.isDeleteModalShown = true;
    })
  }
  async removeBlacklist() {
    this.selectedPage.isLoading = true;
    try {
      await deleteBlacklistPage(this.selectedPage.id);
      let removedIndex = this.blacklistpages.findIndex(it => it.id === this.selectedPage.id);
      this.blacklistpages.splice(removedIndex, 1)
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.selectedPage.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedPage.isLoading = false;
    }
  }

  closeModals() {
    this.isAddModalShown = false;
    this.isEditModalShown = false;
    this.isDeleteModalShown = false;

    this.$nextTick(() => {
      this.selectedPage = new Project.BlacklistForm();
    })
  }
  
  async mounted() {
    this.isPageLoading = true;
    try {
      const pages = await getBlackListPages();
      this.blacklistpages = pages.data
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
