import { RouteConfig } from 'vue-router';
import authPage from '@/pages/auth.vue';
import mainLayout from '@/pages/main.vue';
import serversPage from '@/pages/main/servers.vue';
import serverPagesLayout from '@/pages/main/server/_id.vue';
import serverDetailsPage from '@/pages/main/server/index.vue';
import serverHistoryPage from '@/pages/main/server/history.vue';
// import serverEditPage from '@/pages/main/server/edit.vue';
import projectsPage from '@/pages/main/projects.vue';
import projectPagesLayout from '@/pages/main/project/_id.vue';
import projectDetailsPage from '@/pages/main/project/index.vue';
import projectHistoryPage from '@/pages/main/project/history.vue';
// import projectEditPage from '@/pages/main/project/edit.vue';
import commentsListPage from '@/pages/main/comments/index.vue';
import commentsLogsPage from '@/pages/main/comments/logs.vue';
import commentDetailsPage from '@/pages/main/comments/comment/_id.vue';
import usersPage from '@/pages/main/users.vue';
import userPagesLayout from '@/pages/main/user/_id.vue';
import userDetailsPage from '@/pages/main/user/index.vue';
import userHistoryPage from '@/pages/main/user/history.vue';
import modersPage from '@/pages/main/moders.vue';
import reportsPage from '@/pages/main/reports.vue';
import projectReportLayout from '@/pages/main/report/_id.vue';
import projectReportsPage from '@/pages/main/report/index.vue';
import projectViewedReportsPage from '@/pages/main/report/viewed.vue';
import notificationsPage from '@/pages/main/notifications.vue';
import feedLayout from '@/pages/main/feed.vue';
import feedPage from '@/pages/main/feed/index.vue';
import feedViewedPage from '@/pages/main/feed/viewed.vue';
import categoriesPage from '@/pages/main/categories.vue';
import versionsPage from '@/pages/main/versions.vue';
import quickFiltersPage from '@/pages/main/quick-filters.vue';
import blacklistPage from '@/pages/main/blacklist.vue';
import promocodesPage from '@/pages/main/promocodes.vue';
import discountPage from '@/pages/main/discount.vue';
import statsPage from '@/pages/main/stats.vue';

export const routes: RouteConfig[] = [
  {
    path: '/auth',
    name: 'auth',
    component: authPage
  },
  {
    path: '/',
    component: mainLayout,
    children: [
      {
        path: '',
        redirect: 'servers'
      },
      {
        path: '/servers',
        name: 'servers',
        component: serversPage
      },
      // {
      //   path: '/servers/:id/edit',
      //   name: 'server-edit',
      //   component: serverEditPage
      // },
      {
        path: '/servers/:id',
        component: serverPagesLayout,
        children: [
          {
            path: '',
            name: 'server-details',
            component: serverDetailsPage
          },
          {
            path: 'history',
            name: 'server-history',
            component: serverHistoryPage
          },
          {
            path: '*',
            redirect: '/servers/:id'
          },
        ]
      },
      {
        path: '/projects',
        name: 'projects',
        component: projectsPage
      },
      // {
      //   path: '/projects/:id/edit',
      //   name: 'project-edit',
      //   component: projectEditPage
      // },
      {
        path: '/projects/:id',
        component: projectPagesLayout,
        children: [
          {
            path: '',
            name: 'project-details',
            component: projectDetailsPage
          },
          {
            path: 'history',
            name: 'project-history',
            component: projectHistoryPage
          },
          {
            path: '*',
            redirect: '/project/:id'
          },
        ]
      },
      {
        path: '/comments/',
        name: 'comments-list',
        component: commentsListPage
      },
      {
        path: '/comments/logs/',
        name: 'comments-logs',
        component: commentsLogsPage
      },
      {
        path: '/comments/:id',
        name: 'comment-details',
        component: commentDetailsPage,
      },
      {
        path: '/users',
        name: 'users',
        component: usersPage,
      },
      {
        path: '/users/:id',
        component: userPagesLayout,
        children: [
          {
            path: '',
            name: 'user-details',
            component: userDetailsPage
          },
          {
            path: 'history',
            name: 'user-history',
            component: userHistoryPage
          },
          {
            path: '*',
            redirect: '/users/:id'
          },
        ]
      },
      {
        path: '/moders',
        name: 'moders',
        component: modersPage,
      },
      {
        path: '/reports',
        name: 'reports',
        component: reportsPage,
      },
      {
        path: '/reports/:id',
        component: projectReportLayout,
        children: [
          {
            path: '',
            name: 'project-reports',
            component: projectReportsPage
          },
          {
            path: 'viewed',
            name: 'project-viewed-reports',
            component: projectViewedReportsPage
          },
          {
            path: '*',
            redirect: '/reports/:id'
          },
        ]
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: notificationsPage,
      },
      {
        path: '/feed',
        component: feedLayout,
        children: [
          {
            path: '',
            name: 'feed-page',
            component: feedPage
          },
          {
            path: 'viewed',
            name: 'feed-viewed-page',
            component: feedViewedPage
          },
          {
            path: '*',
            redirect: '/feed'
          },
        ]
      },
      {
        path: '/categories',
        name: 'categories',
        component: categoriesPage,
      },
      {
        path: '/versions',
        name: 'versions',
        component: versionsPage,
      },
      {
        path: '/quick-filters',
        name: 'quick-filters',
        component: quickFiltersPage,
      },
      {
        path: '/blacklist',
        name: 'blacklist',
        component: blacklistPage,
      },
      {
        path: '/promocodes',
        name: 'promocodes',
        component: promocodesPage,
      },
      {
        path: '/discount',
        name: 'discount',
        component: discountPage,
      },
      {
        path: '/stats',
        name: 'stats',
        component: statsPage,
      },
      {
        path: '*',
        redirect: '/'
      },
    ]
  },
];
