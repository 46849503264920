import { Wallet } from '@/types';
import { jsonClient } from '../config';

export function getPromocodesList(offset?: number, limit?: number, code?: string, state?: string) {
  return jsonClient.get<Wallet.PromocodesListResponse>(`/admin/promo-codes/`, { params: { offset, limit, code, state } })
}

export function createPromocode(code: string, discount_percent: number | string, expires: string) {
  return jsonClient.post<Wallet.PromocodeItem>(`/admin/promo-codes/`, { code, discount_percent, expires: new Date(expires).toISOString() })
}

export function deactivatePromocode(promoID: number | string) {
  return jsonClient.post<Wallet.PromocodeItem>(`/admin/promo-codes/${promoID}/deactivate/`)
}

export function updatePromocode(promoID: number | string, code: string, discount_percent: number | string, expires: string) {
  return jsonClient.patch<Wallet.PromocodeItem>(`/admin/promo-codes/${promoID}/`, { code, discount_percent, expires: new Date(expires).toISOString() })
}

export function getOvertakeDiscountList(offset?: number, limit?: number) {
  return jsonClient.get<Wallet.DiscountListResponse>(`/admin/leader-overtake-discount/`, { params: { offset, limit } })
}

export function createOvertakeDiscount(position: number, discount: number) {
  return jsonClient.post<any>(`/admin/leader-overtake-discount/`, { position, discount })
}

export function updateOvertakeDiscount(id: number | string, position?: number, discount?: number) {
  return jsonClient.patch<any>(`/admin/leader-overtake-discount/${id}/`, { position, discount })
}

export function deleteOvertakeDiscount(id: number | string) {
  return jsonClient.delete<any>(`/admin/leader-overtake-discount/${id}/`)
}
