import axios, { AxiosRequestConfig } from 'axios';
import { get as getCookie } from 'es-cookie';

export module Config {
  export module DefaultRequestConfig {
    export const baseURL = process.env.VUE_APP_API_BASE_URL;
    export const responseType = 'json'
    export const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  }

  export function createClient(axiosConfig?: AxiosRequestConfig) {
    
    const instance = axios.create({
      ...Config.DefaultRequestConfig,
      ...axiosConfig
    });

    instance.interceptors.request.use(
      async (res) => {
        const accessToken = await getCookie('auth.token');
        if (accessToken)
          res.headers['Authorization'] = `Bearer ${accessToken}`;
        return res;
      },
      (error) => {
        return Promise.reject(error.response);
      }
    )

    instance.interceptors.response.use(
      (res) => { return res; },
      (error) => {
        if (error.response.status === 401) {
          console.log('401');
          console.log(error.response);
        } else {
          return Promise.reject(error.response);
        }
      } 
    )
    return instance;
  }
};
