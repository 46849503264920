
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Project } from '@/types';
import { State } from 'vuex-class';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ScreenshotsCarouselComponent from '@/components/ScreenshotsCarouselComponent.vue';

@Component({
  components: {
    ButtonComponent,
    ScreenshotsCarouselComponent
  }
})
export default class ScreenshotsSliderComponent extends Vue {
  @Prop({ type: Array, default: [] }) screenshots: Project.Screenshot[];
  @Prop({ type: String, default: '' }) video_link: string;
  @Prop({ type: String, default: '' }) title: string;

  isCarouselOpen: boolean = false;
  currentSlide: number = 1;
  minScrollValue: number = 48;
  sliderScrollLeft: number = 0;
  btnsEnable: boolean[] = [true, true];
  isDragging: boolean = false;
  isClickDisabled: boolean = false;
  startx: number = 0;
  diffx: number = 0;

  get videoPreview() {
    const reg = /youtube\.com.*(\?v=|\/embed\/)(.{11})/;
    const video_id = this.video_link ? this.video_link.match(reg).pop() : '';
    return video_id.length == 11 ? `//img.youtube.com/vi/${video_id}/0.jpg` : '';
  }

  openCarousel(index: number) {
    if (this.isClickDisabled) return;

    this.currentSlide = (index + 1);
    this.isCarouselOpen = true;
  }
  hideCarousel() {
    this.currentSlide = 1;
    this.isCarouselOpen = false;
  }
  scrollBack() {
    const slider = (this.$refs.slider as HTMLElement);
    const windowWidth = slider.offsetWidth;
    slider.scrollTo({ top: 0, left: (this.sliderScrollLeft - (windowWidth * 0.7)), behavior: 'smooth' });
  }
  scrollForward() {
    const slider = (this.$refs.slider as HTMLElement);
    const windowWidth = slider.offsetWidth;
    slider.scrollTo({ top: 0, left: (this.sliderScrollLeft + (windowWidth * 0.7)), behavior: 'smooth' });
  }
  onSliderScroll() {
    const slider = (this.$refs.slider as HTMLElement);
    if (slider === null || slider === undefined) return;

    const windowWidth = slider.offsetWidth;
    const scrollWidth = slider.scrollWidth;
    this.sliderScrollLeft = slider.scrollLeft;

    if (this.sliderScrollLeft < this.minScrollValue) this.btnsEnable = [false, true];
    else if ((this.sliderScrollLeft + windowWidth) >= (scrollWidth - this.minScrollValue)) this.btnsEnable = [true, false];
    else this.btnsEnable = [true, true];
  }
  onMouseDown(e) {
    const slider = (this.$refs.slider as HTMLElement);
    if (slider === null || slider === undefined) return;

    if (!e) { e = window.event; }
    if (e.target && e.target.nodeName === 'IMG') {
      e.preventDefault();
    } else if (e.srcElement && e.srcElement.nodeName === 'IMG') {
      e.returnValue = false;
    }
    this.startx = e.clientX + slider.scrollLeft;
    this.diffx = 0;
    this.isDragging = true;
  }
  onMouseMove(e) {
    // e.preventDefault();
    // e.stopPropagation();
    const slider = (this.$refs.slider as HTMLElement);
    if (slider === null || slider === undefined) return;
    
    if (this.isDragging === true) {
      if (!e) { e = window.event; }
      this.diffx = (this.startx - (e.clientX + slider.scrollLeft));
      slider.scrollLeft += this.diffx;
      this.isClickDisabled = true;
      // $(slider.querySelectorAll('a')).bind("click.prevent", function(event) { event.preventDefault(); });
    }
  }
  onMouseUp(e) {
    if (!e) { e = window.event; }
    // e.preventDefault();
    // e.stopPropagation();
    var start = 1;
    this.isDragging = false;
    setTimeout(() => this.isClickDisabled = false, 300);
    // setTimeout(() => { $(slider.querySelectorAll('a')).unbind("click.prevent"); }, 300);
  }

  mounted() {
    this.onSliderScroll();
    window.addEventListener('mousemove', this.onMouseMove, false);
    window.addEventListener('mouseup', this.onMouseUp, false);
  }

  beforeDestroy() {
    window.removeEventListener('mousemove', this.onMouseMove, false);
    window.removeEventListener('mouseup', this.onMouseUp, false);
  }
}
