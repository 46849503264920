import { Comment, Common } from '@/types';
import { jsonClient } from '../config';

export function getCommentsList(offset?: number, limit?: number, state?: string, search?: string) {
  return jsonClient.get<Comment.CommentsListResponse>('/admin/comments/', { params: { offset, limit, state, search } })
}

export function getCommentsLogs(offset?: number, limit?: number, search?: string) {
  return jsonClient.get<Comment.CommentLogsResponse>('/admin/comments/log-history/', { params: { offset, limit, search } })
}

export function getCommentByID(id: string | number) {
  return jsonClient.get<Comment.Comment>(`/admin/comments/${id}/`)
}

export function getCommentLog(id: string | number) {
  return jsonClient.get<Comment.CommentLogsResponse>(`/admin/comments/${id}/log/`)
}

export function acceptComment(id: string | number) {
  return jsonClient.post<Comment.CommentLogItem>(`/admin/comments/${id}/accept/`)
}

export function banComment(id: string | number, ban_reason: Common.BanReason, ban_details?: string) {
  return jsonClient.post<Comment.CommentLogItem>(`/admin/comments/${id}/ban/`, { ban_reason, ban_details })
}

export function removeComment(id: string | number) {
  return jsonClient.post<Comment.CommentLogItem>(`/admin/comments/${id}/delete/`)
}