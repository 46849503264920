
import { Component, Vue } from 'vue-property-decorator';
import { Action, namespace } from 'vuex-class';
const user = namespace('user');
import { User } from '@/types';
import { roots } from '@/utils';
import { getTags, getVersions } from '@/api/services/servers'
import ButtonComponent from '@/components/ButtonComponent.vue'

@Component({
  metaInfo: {
    title: 'Админ панель'
  },
  components: {
    ButtonComponent
  }
})
export default class MainLayout extends Vue {
  @user.State user: User.Profile;
  @user.Action logout;
  // @Action init;

  nav = [
    { to: 'servers', query: { st: 'moderation' }, name: 'Модерация серверов' },
    { to: 'projects', query: { st: 'moderation' }, name: 'Модерация проектов' },
    { to: 'feed-page', name: 'Действия в ЛК' },
    { to: 'comments-list', name: 'Комментарии' },
    { to: 'users', name: 'Пользователи' },
    { to: 'reports', name: 'Жалобы' },
    { to: 'notifications', name: 'Уведомления' },
    { to: 'categories', name: 'Управление категориями' },
    { to: 'versions', name: 'Управление версиями' },
    { to: 'quick-filters', name: 'Управление быстрыми фильтрами' },
    { to: 'blacklist', name: 'Страницы Blacklist' },
    { to: 'promocodes', name: 'Управление промокодами' },
    { to: 'discount', name: 'Скидки на промо-баллы' },
    { to: 'stats', name: 'Статистика' },
    { to: 'moders', name: 'Управление командой' }
  ]

  get navList() {
    return this.user && this.user.role ? (this.user.role as string) != 'admin' ? this.nav.filter(it => roots[this.user.role].includes(it.to)) : this.nav : [];
  }

  // created() { this.init(); }
}
