
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { monthFullList } from '../util/dateUtils';
import { getComponentRoot } from '../util/componentUtils';
import { parse, isSameMonth, differenceInDays } from 'date-fns';
import WeekText from './WeekText.vue';
import CalendarMonth from './CalendarMonth.vue';

@Component({
  components: { 
    WeekText,
    CalendarMonth
  }
})
export default class CalendarComponent extends Vue {
  @Prop({ type: Date }) inDate: Date;
  @Prop({ type: Date }) outDate: Date;
  @Prop({ type: Date }) minDate: Date;
  @Prop({ type: Date }) maxDate: Date;
  @Prop({ type: Date }) selectedDate: Date;

  firstDayOfWeek: number = 1;
  componentRoot = getComponentRoot(this);
  monthsCount: number = 2;
  displayDateCurrentMonth: Date = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 15);
  displayDateNextMonth: Date = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 15);
  displayMonthsDate: Date[] = [];

  get isPrevMonthAvailable() {
    return !(this.displayMonthsDate[0] && isSameMonth(this.displayMonthsDate[0], new Date(this.minDate)));
  }

  get isNextMonthAvailable() {
    // return this.displayMonthsDate[this.monthsCount - 1] ? differenceInDays(parse(this.displayMonthsDate[this.monthsCount - 1]), new Date(this.)) < 545 : false;
    return !(this.displayMonthsDate[this.monthsCount - 1] && isSameMonth(this.displayMonthsDate[this.monthsCount - 1], new Date(this.maxDate)));
  }

  showMonthYear(date) { return `${monthFullList[date.getMonth()]} ${date.getFullYear()}`;  }

  getDisplayMonths(count) {
    const today = new Date();
    let array: Date[] = [];
    if (count === 2) {
      for (let i = 0; i < count; i++) {
        const item = array[i - 1] ? new Date(array[i - 1]) : null;
        const date = item ? new Date(item.getFullYear(), item.getMonth() + 1, 15) : new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 15);
        array.push(date);
      }
    } else {
      for (let i = 0; i < count; i++) {
        const item = array[i - 1] ? new Date(array[i - 1]) : null;
        const date = item ? new Date(item.getFullYear(), item.getMonth() + 1, 15) : new Date(today.getFullYear(), today.getMonth(), 15);
        array.push(date);
      }
    }
    this.displayMonthsDate = array;
  }

  prevMonth() {
    let months = [];
    this.displayMonthsDate.forEach((element) => {
      const date = new Date(element);
      months.push(new Date(date.getFullYear(), date.getMonth() - 1, 15));
    });
    this.displayMonthsDate = months;
  }

  nextMonth() {
    let months = [];
    this.displayMonthsDate.forEach((element) => {
      const date = new Date(element);
      months.push(new Date(date.getFullYear(), date.getMonth() + 1, 15));
    });
    this.displayMonthsDate = months;
  }

  async mounted() {
    await this.getDisplayMonths(this.monthsCount);
  }
}
