import { UI } from './ui';
import isMatch from 'date-fns/isMatch';
// import format from 'date-fns/format';

export namespace Wallet {
  export interface PromocodesListResponse {
    count: number;
    results: PromocodeItem[];
  }

  export interface DiscountListResponse {
    count: number;
    results: LeaderOvercome[];
  }

  export interface PromocodeItem {
    id: number;
    code: string;
    discount_percent: number;
    expires: Date;
    state: PromocodeState;
  }

  export enum PromocodeState {
    active = 'active',
    deactivated = 'deactivated'
  }

  export class PromocodeForm extends UI.Form {
    id: number = null;
    code: UI.FormField;
    discount_percent: UI.FormField;
    expires: UI.FormField;

    constructor(promo?: PromocodeItem) {
      super();
      this.id = promo ? promo.id : null;
      this.code = new UI.FormField(promo ? String(promo.code) : '');
      this.discount_percent = new UI.FormField(promo ? String(promo.discount_percent) : '');
      this.expires = new UI.FormField(promo ? new Date(promo.expires).formatExpireDateTime() : '');
    }

    isValid(): boolean {
      if (!this.code.isValid()) return false;
      if (!this.discount_percent.isValid()) return false;
      if (!this.expires.isValid()) return false;
      if (!isMatch(this.expires.value, 'yyyy-MM-dd, H:mm')) {
        this.expires.error = 'Неверный формат даты/времени'
        return false
      };
      return true;
    }
  }

  export interface LeaderOvercome {
    id: number;
    position: number;
    discount: number;
  }

  export class LeaderOvercomeForm extends UI.Form {
    id: number = null;
    position: UI.FormField;
    discount: UI.FormField;
    
    constructor(discount?: LeaderOvercome) {
      super();
      this.id = discount ? discount.id : null;
      this.position = new UI.FormField(discount ? String(discount.position) : '');
      this.discount = new UI.FormField(discount ? String(discount.discount) : '');
    }

    isValid(): boolean {
      if (!this.position.isValid()) return false;
      if (!this.discount.isValid()) return false;
      return true;
    }
  }
}