
import { Vue, Component, Prop } from 'vue-property-decorator';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

@Component({
  components: {
    SpinnerComponent
  }
})
export default class SearchComponent extends Vue {
  @Prop({ type: String, default: '' }) value: string;
  @Prop({ type: String, default: 'Поиск' }) placeholder: string;
  @Prop({ type: Boolean, default: false }) isLoading: boolean;

  get localValue() { return this.value }
  set localValue(value: string) { this.$emit('input', value); }
}
