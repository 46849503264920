
import { UI, Common } from './ui';
import { User } from './user';
// import "~/extensions/date";
import { formatVersion } from '@/utils';

export namespace Project {
  export interface ServersListResponse {
    count: number;
    results: Server[];
  }

  export interface ProjectsListResponse {
    count: number;
    results: Project[];
  }

  export class VersionsResponse {
    'bedrock': Version[];
    'pc': Version[];
    
    constructor(versions: VersionsResponse) {
      this.bedrock = versions.bedrock.map(it => new Version(it.id, it.major, it.minor, it.micro, it.type, it.is_emphasized, it.is_generalizing))
      this.pc = versions.pc.map(it => new Version(it.id, it.major, it.minor, it.micro, it.type, it.is_emphasized, it.is_generalizing))
    }
  }

  export interface ServerLogsResponse {
    count: number;
    results: ServerLogItem[];
  }

  export interface ProjectLogsResponse {
    count: number;
    results: ProjectLogItem[];
  }

  export interface TagsResponse {
    main: TagGroup;
    mini: TagGroup;
    mod: TagGroup;
    plugin: TagGroup;
  }

  export interface GetProjectsResponse {
    count: number;
    results: Project[];
  }

  export interface GetReportsGroupedResponse {
    count: number;
    results: ReportGroup[];
  }

  export interface GetProjectReportsResponse {
    count: number;
    results: ReportItem[];
  }

  export interface Server {
    address: string;
    date_created: Date;
    id: number;
    name: string;
    online: number;
    max_online: number;
    owner: number;
    port: number;
    project: number;
    state: ServerState;
    tags: number[];
    versions: number[];
    wipe_date: string;
    claim?: Claim;
    ip_change?: IPChange;
    last_action?: LastAction;
    sequence: number;
    recent_uptime: number;
    map_link?: string;
  }

  export class Project {
    id: number;
    date_created: Date;
    last_edit_time: Date;
    servers: Server[];
    screenshots: Screenshot[];
    name: string;
    inner_url: string;
    motto: string;
    banner: string;
    banner2: string;
    launcher_link: string;
    description: string;
    project_site_link: string;
    vk_link: string;
    discord_link: string;
    video_link: string;
    encouragement: boolean;
    encouragement_link: string;
    owner: User.Profile;
    lobby: number;
    favorited: Favourite;
    type: ServerType;
    online: number;
    max_online: number;
    max_online_recorded: number;
    version_l: number;
    version_r: number;
    points: number;
    rank: number;
    state: ProjectState;
    score: number;
    votes: number;
    penalties: ProjectModeration[];
    promo_colors: PromoteColorMinimal[];
    promo_points: PromotePointMinimal[];
    visits_today?: number;
    last_action?: LastAction;

    constructor(project: Project) {
      this.id = project.id;
      this.date_created = project.date_created;
      this.last_edit_time = project.last_edit_time;
      this.servers = project.servers;
      this.screenshots = project.screenshots;
      this.name = project.name;
      this.inner_url = project.inner_url;
      this.motto = project.motto;
      this.banner = project.banner;
      this.banner2 = project.banner2;
      this.launcher_link = project.launcher_link;
      this.description = project.description;
      this.project_site_link = project.project_site_link;
      this.vk_link = project.vk_link;
      this.discord_link = project.discord_link;
      this.video_link = project.video_link;
      this.encouragement = project.encouragement;
      this.encouragement_link = project.encouragement_link;
      this.owner = project.owner;
      this.lobby = project.lobby;
      this.favorited = project.favorited;
      this.type = project.type;
      this.online = project.online;
      this.max_online = project.max_online;
      this.max_online_recorded = project.max_online_recorded;
      this.version_l = project.version_l;
      this.version_r = project.version_r;
      this.rank = project.rank;
      this.points = project.points;
      this.state = project.state;
      this.score = project.score;
      this.votes = project.votes;
      this.penalties = project.penalties;
      this.promo_colors = project.promo_colors;
      this.promo_points = project.promo_points;
      this.visits_today = project.visits_today;
      this.last_action = project.last_action;
    }
  }

  export interface Claim {
    code: string;
    id: number;
    server: number;
    user: number;
  }

  export interface IPChange {
    address: string;
    code: string
    port: number
  }
  
  export interface Screenshot {
    id: number;
    pic: string;
    project: number;
  }

  export class Version {
    id: number;
    major: number;
    micro: number;
    minor: number
    type: ServerType;
    is_emphasized: boolean;
    is_generalizing: boolean;
    value?: string;

    constructor(id: number, major: number, minor: number, micro: number, type: ServerType, is_emphasized: boolean, is_generalizing: boolean) {
      this.id = id;
      this.major = major;
      this.minor = minor;
      this.micro = micro;
      this.type = type;
      this.is_emphasized = is_emphasized;
      this.is_generalizing = is_generalizing;
      this.value = formatVersion(major, minor, micro);
    }
  }

  export class Tag {
    id: number;
    group: TagGroupType;
    name: string;
    value: string;
    description: string;
  }

  export interface TagGroup {
    name: string;
    items: Tag[];
  }

  export enum ServerState {
    NEW = 'new',
    OWNER_VERIFIED = 'owner_verified',
    MODERATION = 'moderation',
    DECLINED = 'declined',
    ACTIVE = 'active',
    BANNED = 'banned'
  }

  export enum ProjectState {
    BANNED = 'banned',
    CREATED = 'created',
    MODERATION = 'moderation',
    DECLINED = 'declined',
    OK = 'ok',
  }

  export enum ServerType {
    PC = 'pc',
    PC_MOD = 'pc mod',
    BEDROCK = 'bedrock'
  }

  export enum TagGroupType {
    MAIN = 'main',
    MINI = 'mini',
    MOD = 'mod',
    PLUGIN = 'plugin'
  }

  export interface Favourite {
    id: number;
    user: number;
    project?: Project;
  }

  export interface BlacklistItem {
    id: number;
    name: string;
    url: string;
  }

  export class ChangingFrom extends UI.Form {
    input: UI.FormField = new UI.FormField();
    isOpen: boolean = false;
    isLoading: boolean = false;

    constructor(value?: string) {
      super();
      this.input = new UI.FormField(value ? value : '')
    }
  }

  export class ProjectEditForm extends UI.Form {
    name: UI.FormField;
    inner_url: ChangingFrom;
    motto: UI.FormField;
    banner: UI.ImageUpload;
    launcher_link: UI.FormField;
    description: UI.FormField;
    project_site_link: UI.FormField;
    vk_link: UI.FormField;
    video_link: UI.FormField;
    screenshots: UI.ImageUpload[];
    encouragement: boolean;
    encouragement_link: UI.FormField;
    delete_screenshots: number[] = [];

    constructor(name?: string,
                inner_url?: string,
                motto?: string,
                banner?: string,
                launcher_link?: string,
                description?: string,
                project_site_link?: string,
                vk_link?: string,
                video_link?: string,
                screenshots?: Screenshot[],
                encouragement?: boolean,
                encouragement_link?: string) {

      super();
      this.name = new UI.FormField(name ? name : '');
      this.inner_url = new ChangingFrom(inner_url ? inner_url : '');
      if (!inner_url || (inner_url && inner_url.length === 0)) this.inner_url.isOpen = true;
      this.motto =  new UI.FormField(motto ? motto : '');
      this.banner = new UI.ImageUpload(banner ? banner : '');
      this.launcher_link =  new UI.FormField(launcher_link ? launcher_link : '');
      this.description =  new UI.FormField(description ? description : '');
      this.project_site_link = new UI.FormField(project_site_link ? project_site_link : '');
      this.vk_link = new UI.FormField(vk_link ? vk_link : '');
      this.video_link = new UI.FormField(video_link ? video_link : '');
      this.screenshots = screenshots && screenshots.length > 0 ? screenshots.map(it => new UI.ImageUpload(it.pic, null, '', it.id)) : [];
      this.encouragement = encouragement ? encouragement : false;
      this.encouragement_link = new UI.FormField(encouragement_link ? encouragement_link : '');
    }

    resetErrors() {
      this.name.error = '';
      this.inner_url.error = '';
      this.inner_url.input.error = '';
      this.motto.error = '';
      this.banner.error = '';
      this.launcher_link.error = '';
      this.description.error = '';
      this.project_site_link.error = '';
      this.vk_link.error = '';
      this.video_link.error = '';
      this.encouragement_link.error = '';
    }
  }

  export class ServerSettingsForm extends UI.Form {
    name: UI.FormField;
    is_lobby: boolean;
    tags: number[];
    versions: number[];
    wipe_date: UI.FormField;
    address: ChangingFrom;

    constructor(name?: string,
                is_lobby?: boolean,
                tags?: number[],
                versions?: number[],
                wipe_date?: string,
                address?: string,
                port?: string | number) {
      super();
      this.name = new UI.FormField(name ? name : '');
      this.is_lobby = is_lobby ? is_lobby : false;
      this.tags = tags && tags.length > 0 ? tags : [];
      this.versions = versions && versions.length > 0 ? versions : [];
      this.wipe_date = new UI.FormField(wipe_date ? wipe_date : '');
      this.address = new ChangingFrom(address ? `${address}${port ? ':'+port : ''}` : '');
    }
  }

  export class VersionForm extends UI.Form {
    id: number = null;
    major: UI.FormField;
    minor: UI.FormField;
    micro: UI.FormField;
    type: { name: ServerType, error: string };
    value: string = '';
    is_emphasized: boolean = false;
    is_generalizing: boolean = false;
    is_emphasized_error: string = '';
    is_generalizing_error: string = '';

    constructor(version?: Version) {
      super();
      this.id = version ? version.id : null
      this.major = new UI.FormField(version ? String(version.major) : '');
      this.minor = new UI.FormField(version ? String(version.minor) : '');
      this.micro =  new UI.FormField(version && version.micro != null ? String(version.micro) : '');
      this.type = version ? { name: version.type, error: '' } : { name: null, error: '' };
      this.value = version && version.value ? version.value : null;
      this.is_emphasized = version && version.is_emphasized != null ? version.is_emphasized : false;
      this.is_generalizing = version && version.is_generalizing != null ? version.is_generalizing : false;
    }
  }

  export class TagForm extends UI.Form {
    id: number = null;
    name: UI.FormField;
    value: UI.FormField;
    description: UI.FormField;
    group: { type: TagGroupType, name: string, error: string };

    constructor(tag?: Tag, groupName?: string) {
      super();
      this.id = tag ? tag.id : null;
      this.name = new UI.FormField(tag ? String(tag.name) : '');
      this.value = new UI.FormField(tag ? String(tag.value) : '');
      this.description = new UI.FormField(tag ? String(tag.description) : '');
      this.group = tag ? { type: tag.group, name: groupName ? groupName : '', error: '' } : { type: null, name: '', error: '' };
    }
  }

  export class BlacklistForm extends UI.Form {
    id: number = null;
    name: UI.FormField;
    url: UI.FormField;

    constructor(page?: BlacklistItem) {
      super();
      this.id = page ? page.id : null;
      this.name = new UI.FormField(page ? String(page.name) : '');
      this.url = new UI.FormField(page ? String(page.url) : '');
    }
  }

  export enum ModerationAction {
    APPROVE = 'approve',
    DECLINE = 'decline',
    BAN = 'ban',
    UNBAN = 'unban',
    PENALIZE = 'penalize'
  }

  export class PenalizeExpireValues {
    7 = { name: '1 неделя', value: 7 };
    14 = { name: '2 недели', value: 14 };
    21 = { name: '3 недели', value: 21 };
    30 = { name: '1 месяц', value: 30 };
    45 = { name: '1.5 месяца', value: 45 };
    60 = { name: '2 месяца', value: 60 };
    
    constructor() {}

    getAsArray() {
      return Object.values(this);
    }
  }

  export class PenalizePointsValues {
    50 = { value: 50 };
    100 = { value: 100 };
    200 = { value: 200 };
    300 = { value: 300 };
    400 = { value: 400 };
    500 = { value: 500 };
    
    constructor() {}

    getAsArray() {
      return Object.values(this);
    }
  }

  export class PenaltyForm extends Common.BanForm {
    penalty_points: any = null;
    penalty_pointsError: string = ''; 
    penalty_expires: any = null;
    penalty_expiresError: string = '';

    constructor() {
      super();
    }

    isValid(): boolean {
      if (this.ban_reason === null) {
        this.ban_reasonError = 'Укажите причину';
        return false
      }
      if (this.ban_reason === Common.BanReason.OTHER && this.ban_details.value.length === 0) {
        this.ban_details.error = 'Опишите причину'
        return false;
      }
      if (this.penalty_points === null) {
        this.penalty_pointsError = 'Укажите штраф'
        return false;
      }
      if (this.penalty_expires === null) {
        this.penalty_expiresError = 'Укажите срок штрафа'
        return false;
      }
      return true;
    }

    resetErrors() {
      this.ban_reasonError = '';
      this.ban_details.error = '';
      this.penalty_pointsError = '';
      this.penalty_expiresError = '';
      this.error = '';
    }
  }

  export interface ServerLogItem {
    id: number;
    server: number;
    action: ModerationAction;
    actor: User.Profile;
    reason: Common.BanReason;
    details: string;
    timestamp: Date;
  }

  export interface ProjectLogItem {
    id: number;
    project: number;
    action: ModerationAction;
    actor: User.Profile;
    reason: Common.BanReason;
    details: string;
    penalty_expires: Date;
    penalty_points: number;
    timestamp: Date;
  }

  export interface ReportItem {
    id: number;
    reason: Common.BanReason;
    details: string;
    project: Project;
    author: User.Profile;
    reviewed: boolean;
    reviewer: User.Profile;
    timestamp: Date;
    reviewed_at: Date;
  }
  
  export interface ReportGroup {
    project: Project;
    new_reports: number;
    last_report_timestamp: Date;
  }

  export interface ProjectModeration {
    id: number;
    project: number;
    action: ModerationAction;
    actor: User.Profile;
    reason: Common.BanReason;
    details: string;
    penalty_expires: Date;
    penalty_points: number;
    timestamp: Date;
  }

  export interface LastAction {
    action: ModerationAction;
    reason: Common.BanReason;
    details: string;
  }

  export enum Color {
    yellow = 'yellow',
    green = 'green',
    cyan = 'cyan',
    blue = 'blue',
    purple = 'purple',
    magenta = 'magenta'
  }

  export class ColorValues {
    green = { name: 'Зеленый', color: '#00bd6e', bgColor: '#e6f8f1', value: 'green' };
    yellow = { name: 'Желтый', color: '#f7b500', bgColor: '#fef8e6', value: 'yellow' };
    cyan = { name: 'Циан', color: '#44d7b6', bgColor: '#ecfbf8', value: 'cyan' };
    blue = { name: 'Синий', color: '#0091ff', bgColor: '#e6f4ff', value: 'blue' };
    purple = { name: 'Пурпурный', color: '#6236ff', bgColor: '#efebff', value: 'purple' };
    magenta = { name: 'Фиолетовый', color: '#b620e0', bgColor: '#f8e9fc', value: 'magenta' };
    
    constructor() {}

    getAsArray() {
      return Object.values(this);
    }
  }

  export interface PromoteColorMinimal {
    color: Color;
    expires: string;
  }

  export interface PromotePointMinimal {
    points: number;
    expires: string;
  }

  export class TransferForm extends UI.Form {
    user: User.Profile = null;
    user_error: string = '';

    constructor() {
      super();
    }

    isValid(): boolean {
      if (!this.user) {
        this.user_error = 'Выберите пользователя';
        return false
      }
    }
  }
}