
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class ButtonComponent extends Vue {
  @Prop({ type: Boolean, default: true }) enable: boolean;
  @Prop({ type: String }) minWidth: string;
  
  cursorPos = {}
  animate: boolean = false;
  width: number = 0;
  height: number = 0;
  top: number = 0;
  left: number = 0;

  @Watch('cursorPos') cursorPosWatcher(val, old) {
    if (this.animate) {
      this.animate = false;
      this.$nextTick(() => {
        this.reppling(val)
      });
    } else this.reppling(val)
  }    

  handleClick(e) {
    this.cursorPos = {
      top: e.clientY,
      left: e.clientX
    }
    if (this.enable) this.$emit('click', e);
  }

  reppling(cursorPos) {
    const $button = (this.$el as HTMLElement);
    const buttonPos = $button.getBoundingClientRect();
    const buttonWidth = $button.offsetWidth;
    const buttonHeight = $button.offsetHeight;
    const rippleWidthShouldBe = Math.max(buttonHeight, buttonWidth);
    const centerize = rippleWidthShouldBe / 2;
    this.animate = true;
    this.width = rippleWidthShouldBe;
    this.height = rippleWidthShouldBe;
    this.top = cursorPos.top - buttonPos.top - centerize;
    this.left = cursorPos.left - buttonPos.left - centerize;
  }
}
