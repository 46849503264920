
import { Component, Vue } from 'vue-property-decorator';
import { getUserByID, moderateUser } from '@/api/services/users';
import { User, Common, UI } from '@/types';
import { errorHandler } from '@/utils';
import ButtonComponent from '@/components/ButtonComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ReportModalComponent from '@/components/ReportModalComponent.vue';
import TextModalComponent from '@/components/TextModalComponent.vue';

@Component({
  components: {
    ButtonComponent,
    SpinnerComponent,
    ReportModalComponent,
    TextModalComponent
  }
})
export default class UserLayout extends Vue {
  isPageLoading: boolean = true;
  user: User.Profile = null;
  isReportModalShown: boolean = false;
  isUnbanModalShown: boolean = false;
  banForm: Common.BanForm = new Common.BanForm;
  unbanForm: UI.Form = new UI.Form;

  reasonsCustom: Common.BanReason[] = [
    Common.BanReason.OBSCENE,
    Common.BanReason.FRAUD,
    Common.BanReason.SPAM,
    Common.BanReason.OTHER
  ]

  async unban() {
    const userID = this.$route.params.id;
    this.unbanForm.isLoading = true;
    try {
      const resp = await moderateUser(userID, User.UserModerationAction.UNBAN);
      this.user = resp.data;
      this.isUnbanModalShown = false;
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.unbanForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.unbanForm.isLoading = false;
    }
  }

  async ban() {
    const userID = this.$route.params.id;
    this.banForm.isLoading = true;
    this.banForm.resetErrors();
    try {
      if (!this.banForm.isValid()) return;
      const resp = await moderateUser(
        userID,
        User.UserModerationAction.BAN,
        this.banForm.ban_reason,
        this.banForm.ban_reason === Common.BanReason.OTHER ? this.banForm.ban_details.value : undefined);
      this.user = resp.data;
      this.isReportModalShown = false;
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (it === 'reason') this.banForm.ban_reasonError = errorHandler(it, errResponse[it][0]);
        else if (it === 'details') this.banForm.ban_details.error = errorHandler(it, errResponse[it][0]);
        else if (this.banForm[it]) this.banForm[it].error = errorHandler(it, errResponse[it][0]);
        else this.banForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.banForm.isLoading = false;
    }
  }

  async mounted() {
    const userID = this.$route.params.id;
    this.isPageLoading = true;
    try {
      const user = await getUserByID(userID);
      this.user = user.data;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
