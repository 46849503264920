
import { Vue, Component, Prop } from 'vue-property-decorator';
import { TheMask } from './TheMask';

@Component({
  components: {
    TheMask
  }
})
export default class InputComponent extends Vue {
  @Prop() value: string | number;
  @Prop({ type: String }) label: string;
  @Prop({ type: String, default: 'text' }) type: string;
  @Prop({ type: Boolean, default: true }) validate: boolean;
  @Prop({ type: String }) name: string;
  @Prop({ type: String }) errorText: string;
  @Prop({ type: String }) placeholder: string;
  @Prop({ type: String }) maxlength: string;
  @Prop({ type: Boolean, default: false }) readonly: boolean;
  // @Prop({ type: String }) autocomplete: string;

  get inputValue() { return this.value; }
  set inputValue(value: string | number) {
    if ((this.type === 'date' || this.type === 'datetime') && this.$refs.input && (this.$refs.input as any).display) this.$emit('input', (this.$refs.input as any).display);
    else this.$emit('input', value);
  }

  get inputSetting() {
    switch(this.type) {
      case 'date': return {
        mask: '####-##-##'
      }
      case 'datetime': return {
        mask: '####-##-##, ##:##'
      }
      case 'numb': return {
        mask: '##########'
      }
      case 'percent': return {
        mask: '###'
      }
      default: return {
        mask: ''
      }
    }
  }
}
