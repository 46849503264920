
import { Component, Vue, Prop } from 'vue-property-decorator';
import { User, Project } from '@/types';
import { getUserProjects } from '@/api/services/users';

@Component
export default class UserDetailsPage extends Vue {
  @Prop({ type: Object }) user: User.Profile;

  userProjects: Project.Project[] = [];

  get socialAccounts() {
    return this.user ? this.user.social_auth.map(it => User.SocialProviderRU[it.provider]) : '';
  }

  async mounted() {
    try {
      const projects = await getUserProjects(this.user.id);
      this.userProjects = projects.data;
    } catch (error) {
      console.log(error)
    }
  }
}
