import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { get as getCookie, set as setCookie, remove as removeCookie } from 'es-cookie';
import { User } from '@/types';
import { getUser } from '@/api/services/users';

export class State {
  token: string = getCookie('token');
  user: User.Profile = null;
}

const getters = <GetterTree<State, any>> {}
const mutations = <MutationTree<State>> {
  setToken(state: State, token: string) {
    state.token = token;
    setCookie('auth.token', token);
  },
  setUser(state: State, user: any) {
    state.user = user;
  },
  logout(state: State) {
    state.token = '';
    removeCookie('auth.token');
  },
}

const actions = <ActionTree<State, any>> {
  async fetchUser(store: ActionContext<State, any>) {
    try {
      const user = await getUser();
      if (User.UserRole[user.data.role] === User.UserRole.user) {
        throw user;
      }
      await store.commit('setUser', user.data);
      return Promise.resolve();
    } catch (error) {
      store.commit('logout');
      return Promise.reject(error)
    }
  },
  logout(store: ActionContext<State, any>) {
    store.commit('logout');
    document.location.reload(true);
  }
}

export const user = {
  namespaced: true,
  state: new State(),
  getters,
  mutations,
  actions,
};