
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Wallet } from '@/types';
import { getOvertakeDiscountList, createOvertakeDiscount, updateOvertakeDiscount, deleteOvertakeDiscount } from '@/api/services/wallet';
import { errorHandler } from '@/utils';
// import SearchComponent from '@/components/SearchComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import TextModalComponent from '@/components/TextModalComponent.vue';
import InputComponent from '@/components/InputComponent.vue';

@Component({
  metaInfo: {
    title: 'Скидки на промо-баллы'
  },
  components: {
    // SearchComponent,
    SpinnerComponent,
    ButtonComponent,
    ModalComponent,
    TextModalComponent,
    InputComponent
  }
})
export default class DiscountPage extends Vue {
  isPageLoading: boolean = true;
  isAddModalShown: boolean = false;
  isEditModalShown: boolean = false;
  isRemoveModalShown: boolean = false;
  discountList: Wallet.LeaderOvercome[] = [];
  discountCount: number = 0;
  selectedDiscount: Wallet.LeaderOvercomeForm = new Wallet.LeaderOvercomeForm();
  isLoadingMore: boolean = false;
  
  openAddModal() {
    this.selectedDiscount = new Wallet.LeaderOvercomeForm();
    this.$nextTick(() => {
      this.isAddModalShown = true;
    })
  }

  async createDiscount() {
    this.selectedDiscount.isLoading = true;
    try {
      if (!this.selectedDiscount.isValid()) return

      const resp = await createOvertakeDiscount(parseInt(this.selectedDiscount.position.value), parseInt(this.selectedDiscount.discount.value));
      this.discountList.unshift(resp.data)
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (this.selectedDiscount[it]) this.selectedDiscount[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedDiscount.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedDiscount.isLoading = false;
    }
  }

  openRemoveModal(item: Wallet.LeaderOvercome) {
    this.selectedDiscount = new Wallet.LeaderOvercomeForm(item);
    this.$nextTick(() => {
      this.isRemoveModalShown = true;
    })
  }

  async removeDiscount() {
    this.selectedDiscount.isLoading = true;
    try {

      await deleteOvertakeDiscount(this.selectedDiscount.id);
      let removedIndex = this.discountList.findIndex(it => it.id === this.selectedDiscount.id);
      this.discountList.splice(removedIndex, 1);
      this.discountCount--;
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (this.selectedDiscount[it]) this.selectedDiscount[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedDiscount.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedDiscount.isLoading = false;
    }
  }

  openEditModal(item: Wallet.LeaderOvercome) {
    this.selectedDiscount = new Wallet.LeaderOvercomeForm(item);
    this.$nextTick(() => {
      this.isEditModalShown = true;
    })
  }

  async updateDiscount() {
    this.selectedDiscount.isLoading = true;
    try {
      if (!this.selectedDiscount.isValid()) return

      const resp = await updateOvertakeDiscount(this.selectedDiscount.id, parseInt(this.selectedDiscount.position.value), parseInt(this.selectedDiscount.discount.value));
      let edited = this.discountList.find(it => it.id === this.selectedDiscount.id);
      edited.position = resp.data.position;
      edited.discount = resp.data.discount;
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (this.selectedDiscount[it]) this.selectedDiscount[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedDiscount.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedDiscount.isLoading = false;
    }
  }

  closeModals() {
    this.isAddModalShown = false;
    this.isEditModalShown = false;
    this.isRemoveModalShown = false;

    this.$nextTick(() => {
      this.selectedDiscount = new Wallet.LeaderOvercomeForm();
    })
  }

  async loadMore() {
    this.isLoadingMore = true;
    try {
      const resp = await getOvertakeDiscountList(this.discountList.length, 10);
      this.discountList.push(...resp.data.results);
      this.discountCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoadingMore = false;
    }
  }

  async mounted() {
    this.isPageLoading = true;
    try {
      const resp = await getOvertakeDiscountList(0, 10);
      this.discountList = resp.data.results;
      this.discountCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
