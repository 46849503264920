
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Project, User } from '@/types';
import { State } from 'vuex-class';
import { getUserByID } from '@/api/services/users';

@Component({})
export default class ServerDetailsPage extends Vue {
  @Prop({ type: Object }) server: Project.Server;
  @Prop({ type: Object }) project: Project.Project;

  @State versions: Project.VersionsResponse;
  @State tags: Project.TagsResponse;

  baseURL: string = process.env.VUE_APP_FRONT_URL;
  baseDjangoURL: string = process.env.VUE_APP_DJANGO_BASE_URL;
  owner: User.Profile = null;

  get categoriesList() {
    if (!this.tags || !this.server || !(this.server && this.server.tags)) return null

    return Object.values(this.tags)
            .filter(group => group.items.some(it => this.server.tags.includes(it.id)))
            .map(group => {
              return { ...group, items: group.items.filter(it => this.server.tags.includes(it.id)) }
            })
  }

  get versionsList(): string {
    const serverType = this.project && this.project.type && this.project.type != Project.ServerType.PC_MOD ? this.project.type : Project.ServerType.PC;
    if (!this.versions || !this.server || !(this.server && this.server.versions)) return null

    const list = this.versions[serverType].filter(it => this.server.versions.includes(it.id));
    if (list.length > 0) {
      const start = list[0].value;
      const end = list.length > 1 ? list[list.length - 1].value : null;
      return `${serverType.toUpperCase()} ${start}${(end) ? ` – ${end}` : ''}`
    } else return ''
  }

  async mounted() {
    if (this.server && this.server.owner) {
      try {
        const user = await getUserByID(this.project.owner.id);
        this.owner = user.data;
      } catch (error) {
        console.log(error)
      }
    }
  }
}
