
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class CheckboxComponent extends Vue {
  @Prop({ type: Boolean, default: true }) value: boolean;
  @Prop({ type: String }) id: string;

  inputValue: boolean = true;

  @Watch('value') onValueChanged(val: boolean, oldVal: boolean) { this.inputValue = val; }

  onClick() {
    this.inputValue = !this.inputValue;
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit('toggle', this.id);
      }, 100);
    });
  }

  mounted() { this.inputValue = this.value; }
}
