
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class RadioComponent extends Vue {
  @Prop() value: any;
  @Prop() radioValue: any;
  @Prop({ type: String, default: '' }) id: string;

  get isSelected() { return this.radioValue === this.value }

  toggleCheck() { this.$emit('input', this.radioValue) }
}
