
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Project } from '@/types';
import { State, Action } from 'vuex-class';
import { errorHandler } from '@/utils';
import { createVersion, updateVersion, deleteVersion } from '@/api/services/servers';
import SearchComponent from '@/components/SearchComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import DropdownComponent from '@/components/DropdownComponent.vue';
import InputComponent from '@/components/InputComponent.vue';
import CheckboxComponent from '@/components/CheckboxComponent.vue';

@Component({
  metaInfo: {
    title: 'Модерация версий'
  },
  components: {
    SearchComponent,
    ButtonComponent,
    ModalComponent,
    SpinnerComponent,
    DropdownComponent,
    InputComponent,
    CheckboxComponent
  }
})
export default class VersionsPage extends Vue {
  @State versions: Project.VersionsResponse;
  @Action updateVersionsList;

  query: string = '';
  selectedVersion: Project.VersionForm = new Project.VersionForm();
  isDeleteModalShown: boolean = false;
  isAddModalShown: boolean = false;
  isEditModalShown: boolean = false;

  get filteredVersions() {
    if (this.versions === null || this.versions === undefined) return [];

    const filterFunc = (it: Project.Version) => {
      return it.value.includes(this.query.toLowerCase()) || it.value.split('.').join("").includes(this.query.split('.').join(""))
    }
    const list = Object.keys(this.versions).map(key => {
      return {
        type: key,
        items: this.versions[key].sort((x, y) => x.id > y.id ? 1 : x.id < y.id ? -1 : 0)
      }
    });

    return this.query.length > 0 ? list.filter(group => group.items.some(it => filterFunc(it))).map(group => { return { ...group, items: group.items.filter(it => filterFunc(it)) } }) : list;
  }
  
  get serverTypes() {
    return Object.values(Project.ServerType).map(it => { return { name: it } }).filter(it => it.name != Project.ServerType.PC_MOD)
  }

  openDeleteModal(version: Project.Version) {
    this.selectedVersion = new Project.VersionForm(version);
    this.$nextTick(() => {
      this.isDeleteModalShown = true;
    })
  }
  async removeVersion() {
    this.selectedVersion.isLoading = true;
    try {
      await deleteVersion(this.selectedVersion.id);
      await this.updateVersionsList();
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.selectedVersion.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedVersion.isLoading = false;
    }
  }

  openAddModal() {
    this.selectedVersion = new Project.VersionForm();
    this.$nextTick(() => {
      this.isAddModalShown = true;
    })
  }
  async addVersion() {
    this.selectedVersion.isLoading = true;
    try {
      await createVersion(this.selectedVersion.major.value,
                          this.selectedVersion.minor.value,
                          this.selectedVersion.micro.value,
                          this.selectedVersion.type.name,
                          this.selectedVersion.is_generalizing,
                          this.selectedVersion.is_emphasized);
      await this.updateVersionsList();
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (this.selectedVersion[it]) this.selectedVersion[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedVersion.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedVersion.isLoading = false;
    }
  }

  openEditModal(version: Project.Version) {
    this.selectedVersion = new Project.VersionForm(version);
    this.$nextTick(() => {
      this.isEditModalShown = true;
    })
  }
  async editVersion() {
    this.selectedVersion.isLoading = true;
    try {
      await updateVersion(this.selectedVersion.id,
                          this.selectedVersion.major.value,
                          this.selectedVersion.minor.value,
                          this.selectedVersion.micro.value,
                          this.selectedVersion.type.name,
                          this.selectedVersion.is_generalizing,
                          this.selectedVersion.is_emphasized);
      await this.updateVersionsList();
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (this.selectedVersion[it]) this.selectedVersion[it].error = errorHandler(it, errResponse[it][0]);
        else this.selectedVersion.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.selectedVersion.isLoading = false;
    }
  }

  closeModals() {
    this.isDeleteModalShown = false;
    this.isAddModalShown = false;
    this.isEditModalShown = false;

    this.$nextTick(() => {
      this.selectedVersion = new Project.VersionForm();
    })
  }
}
