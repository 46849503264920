
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Comment, Common } from '@/types';
import { getCommentsLogs } from '@/api/services/comment';
import debounce from 'lodash/debounce';
import SearchComponent from '@/components/SearchComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

@Component({
  metaInfo: {
    title: 'История модерации комментариев'
  },
  components: {
    SearchComponent,
    SpinnerComponent,
    ButtonComponent
  }
})
export default class CommentsLogsPage extends Vue {
  isPageLoading: boolean = true;
  query: string = this.$route.query && this.$route.query.q ? String(this.$route.query.q) : '';
  logList: Comment.CommentLogItem[] = [];
  logsCount: number = 0;
  isLoadingMore: boolean = false;
  onQueryChangeDebounced: Function = debounce(this.onQueryChange, 350);
  
  @Watch('query') queryWatcher(value: string) {
    this.isPageLoading = true;
    this.onQueryChangeDebounced();
  }

  getActionClass(action: Comment.LogActionType) {
    switch (action) {
      case Comment.LogActionType.accept: return 'black--text';
      case Comment.LogActionType.ban: return 'red--text';
      default: return 'black--text';
    }
  }
  getActionText(action: Comment.LogActionType) {
    switch (action) {
      case Comment.LogActionType.accept: return 'Принят';
      case Comment.LogActionType.ban: return 'Отклонен';
      default: return 'Принят';
    }
  }

  async onQueryChange() {
    try {
      this.$router.push({ query: this.query ? { q: this.query } : null })
      const resp = await getCommentsLogs(0, 10, this.query);
      this.logList = resp.data.results;
      this.logsCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }

  async loadMore() {
    this.isLoadingMore = true;
    try {
      const resp = await getCommentsLogs(this.logList.length, 10, this.query);
      this.logList.push(...resp.data.results);
      this.logsCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoadingMore = false;
    }
  }

  async mounted() {
    const q = this.$route.query && this.$route.query.q ? String(this.$route.query.q) : '';
    this.query = q;
    this.isPageLoading = true;
    try {
      const resp = await getCommentsLogs(0, 10, q);
      this.logList = resp.data.results;
      this.logsCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
