
import { Component, Vue, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import '@/extensions/date';
import { weekDaysShort, monthList } from '../util/dateUtils';

@Component({})
export default class DatepickerInputComponent extends Vue {
  @Prop({ type: String, default: 'Дата' }) placeholder: string
  @Prop() value: string;

  nextDate() { this.$emit('nextDate') }
  prevDate() { this.$emit('prevDate') }

  // get selectedDate() { return this.date ? this.date.formatDate('D MM') : null; }
}
