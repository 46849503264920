import { Project } from '@/types';
import { errorsList } from '@/utils/error-handler';

export function formatVersion(major: number, minor: number, micro: number, separator: string = '.') {
  let arr = [major];
  // if (minor != null && (minor > 0 || micro > 0)) arr.push(minor)
  // if (micro != null && micro > 0) arr.push(micro)
  if (minor != null) arr.push(minor)
  if (micro != null) arr.push(micro)
  return arr.join(separator)
}

export function errorHandler(key, err_code) {
  return errorsList(key)[err_code] ? errorsList(key)[err_code] : errorsList('default')[err_code] ? errorsList('default')[err_code] : 'Неизвестная ошибка';
}

export const roots = {
  moder: ['feed-page', 'feed-viewed-page', 'comments-list', 'comments-logs', 'comment-details', 'reports'],
  senior_moder: ['feed-page', 'feed-viewed-page', 'comments-list', 'comments-logs', 'comment-details', 'reports', 'servers', 'server-details', 'server-history', 'projects', 'project-details', 'project-history', 'users', 'user-details', 'user-history']
} 