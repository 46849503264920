
import { Component, Vue, Prop } from 'vue-property-decorator';

import { Project } from '@/types';
import { errorHandler } from '@/utils';

import { transferProject, transferServer } from '@/api/services/servers';

import ModalComponent from '@/components/ModalComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import UserSelectDropdownComponent from '@/components/UserSelectDropdownComponent.vue';

@Component({
  components: {
    ModalComponent,
    SpinnerComponent,
    ButtonComponent,
    UserSelectDropdownComponent,
  }
})

export default class TransferModalComponent extends Vue {
  @Prop({ type: Boolean, default: false }) isShown: boolean;
  @Prop({ type: String, default: '' }) type: string;
  @Prop({ type: String, default: '' }) name: string;

  transferForm: Project.TransferForm = new Project.TransferForm()
  innerUrl: String = '';

  get getModalTitle () {
    switch (this.type) {
      case 'project':
        return 'Перенос проекта'
      case 'server':
        return 'Перенос сервера'
      default:
        return ''
    }
  }

  get getName () {
    switch (this.type) {
      case 'project':
        return `Название проекта: ${this.name}`
      case 'server':
        return `Название сервера: ${this.name}`
      default:
        return ''
    }
  }

  closeModal () {
    this.transferForm = new Project.TransferForm()
    this.$emit('close')
  }

  async onSubmit () {
    if (!this.transferForm.isValid) {
      return false
    }

    this.transferForm.isLoading = true

    try {
      const { id } = this.$route.params

      if (this.type === 'project') {
        await transferProject(id, this.transferForm.user.id)
      } else if (this.type === 'server') {
        await transferServer(id, this.transferForm.user.id)
      }

      this.$emit('success', this.transferForm.user)
      this.closeModal()
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};

      if (error?.status === 400 && error?.data?.inner_url) {
        this.transferForm.error = 'У сервера есть проект, перенос невозможен'
        this.innerUrl = `/projects/${error.data.id}`
      } else {
        Object.keys(errResponse).forEach(it => {
          if (this.transferForm[it]) this.transferForm[it].error = errorHandler(it, errResponse[it][0]);
          else this.transferForm.error = errorHandler(it, errResponse[it][0])
        })
      }
    } finally {
      this.transferForm.isLoading = false
    }
  }
}
