
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Project, User, Common, UI } from '@/types';
import { errorHandler } from '@/utils';
import { getServerByID, getProjectByID, moderateServer } from '@/api/services/servers';
import ButtonComponent from '@/components/ButtonComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ReportModalComponent from '@/components/ReportModalComponent.vue';
import TextModalComponent from '@/components/TextModalComponent.vue';
import TransferModalComponent from '@/components/TransferModalComponent.vue';

const user = namespace('user');

@Component({
  components: {
    ButtonComponent,
    SpinnerComponent,
    ReportModalComponent,
    TextModalComponent,
    TransferModalComponent,
  }
})
export default class ServerLayout extends Vue {
  @user.State user: User.Profile;

  isPageLoading: boolean = true;
  server: Project.Server = null;
  project: Project.Project = null;
  isReportModalShown: boolean = false;
  isAcceptModalShown: boolean = false;
  isTransferModalShown: boolean = false;
  banForm: Common.BanForm = new Common.BanForm;
  unbanForm: UI.Form = new UI.Form;
  reasonsCustom: Common.BanReason[] = [
    Common.BanReason.WRONG_CATEGORIES,
    Common.BanReason.FRAUD,
    Common.BanReason.SPAM,
    Common.BanReason.OTHER
  ]

  async accept() {
    this.unbanForm.isLoading = true;
    try {
      const resp = await moderateServer(
        this.server.id,
        this.server.state === Project.ServerState.BANNED ? Project.ModerationAction.UNBAN : Project.ModerationAction.APPROVE
      );
      this.server = resp.data;
      this.isAcceptModalShown = false;
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.unbanForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.unbanForm.isLoading = false;
    }
  }

  async report() {
    this.banForm.isLoading = true;
    this.banForm.resetErrors();
    try {
      if (!this.banForm.isValid()) return;

      const resp = await moderateServer(
        this.server.id,
        this.server.state === Project.ServerState.ACTIVE ? Project.ModerationAction.BAN : Project.ModerationAction.DECLINE,
        this.banForm.ban_reason,
        this.banForm.ban_reason === Common.BanReason.OTHER ? this.banForm.ban_details.value : undefined);
      this.server = resp.data;
      this.isReportModalShown = false;
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (it === 'reason') this.banForm.ban_reasonError = errorHandler(it, errResponse[it][0]);
        else if (it === 'details') this.banForm.ban_details.error = errorHandler(it, errResponse[it][0]);
        else if (this.banForm[it]) this.banForm[it].error = errorHandler(it, errResponse[it][0]);
        else this.banForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.banForm.isLoading = false;
    }
  }

  afterTransfer (owner: User.Profile) {
    this.server.owner = owner.id
    this.project.owner = owner
  }

  async mounted() {
    const serverID = this.$route.params.id;
    this.isPageLoading = true;
    try {
      const server = await getServerByID(serverID);
      this.server = server.data;
      if (server.data.project) {
        const project = await getProjectByID(server.data.project);
        this.project = project.data;
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
