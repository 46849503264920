
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class TextareaComponent extends Vue {
  @Prop({ type: String }) value: string;
  @Prop({ type: String, default: '288px' }) height: string;
  @Prop({ type: String, default: '' }) placeholder: string;
  @Prop({ type: Boolean, default: true }) validate: boolean;
  @Prop({ type: Boolean, default: true }) resize: boolean;
  @Prop({ type: String }) errorText: string;

  get inputValue() { return this.value; }
  set inputValue(value: string | number) { this.$emit('input', value); }
}
