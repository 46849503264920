import { User, Project, Common } from '@/types';
import { jsonClient } from '../config';

export function login(formdata) {
  return jsonClient.post<User.LoginResponse>(`/o/token/`, formdata)
}

export function getUser() {
  return jsonClient.get<User.Profile>(`/users/profile`)
}

export function getUsersList(offset?: number, limit?: number, search?: string) {
  return jsonClient.get<User.UsersListResponse>(`/admin/users/`, { params: { offset, limit, search } })
}

export function getUserByID(id: number | string) {
  return jsonClient.get<User.Profile>(`/admin/users/${id}/`)
}

export function getUserProjects(id: number | string) {
  return jsonClient.get<Project.Project[]>(`/admin/users/${id}/projects/`)
}

export function setUserRole(id: number | string, role: string) {
  return jsonClient.post<User.Profile>(`/admin/users/${id}/set-role/`, { role })
}

export function getModersList(search?: string) {
  return jsonClient.get<User.UsersListResponse>(`/admin/users/`, { params: { search, role: '(admin|moder)' } })
}

export function moderateUser(id: string | number, action: User.UserModerationAction, reason?: Common.BanReason, details?: string) {
  return jsonClient.post<User.Profile>(`/admin/users/${id}/moderate/`, { action, reason, details })
}

export function getUserLogs(id: string | number, offset?: number, limit?: number) {
  return jsonClient.get<User.UserLogsResponse>(`/admin/users/${id}/log/`, { params: { offset, limit } })
}