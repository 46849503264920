
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import debounce from 'lodash/debounce';

import { Comment, Common, User } from '@/types';
import { getCommentsList, acceptComment, banComment, removeComment } from '@/api/services/comment';
import { errorHandler } from '@/utils';
import SearchComponent from '@/components/SearchComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ReportModalComponent from '@/components/ReportModalComponent.vue';
import TextModalComponent from '@/components/TextModalComponent.vue';

const user = namespace('user');

@Component({
  metaInfo: {
    title: 'Модерация комментариев'
  },
  components: {
    SearchComponent,
    SpinnerComponent,
    ButtonComponent,
    ReportModalComponent,
    TextModalComponent
  }
})
export default class CommentsPage extends Vue {
  @user.State user: User.Profile;

  isPageLoading: boolean = true;
  query: string = this.$route.query && this.$route.query.q ? String(this.$route.query.q) : '';
  comments: Comment.Comment[] = [];
  commentsCount: number = 0;
  isReportModalShown: boolean = false;
  isUnblockModalShown: boolean = false;
  isRemoveModalShown: boolean = false;
  unbanForm: Common.UnbanForm = new Common.UnbanForm;
  banForm: Common.BanForm = new Common.BanForm;
  removeForm: Common.RemoveForm = new Common.RemoveForm;
  selectedComment: Comment.Comment = null;
  isLoadingMore: boolean = false;
  onQueryChangeDebounced: Function = debounce(this.onQueryChange, 350);

  reasonsCustom: Common.BanReason[] = [
    Common.BanReason.FRAUD,
    Common.BanReason.SPAM,
    Common.BanReason.OBSCENE,
    Common.BanReason.OTHER
  ]
  
  @Watch('query') queryWatcher(value: string) {
    this.isPageLoading = true;
    this.onQueryChangeDebounced();
  }

  async onQueryChange() {
    try {
      this.$router.push({ query: this.query ? { q: this.query } : null })
      const commentsList = await getCommentsList(0, 10, this.query.length > 0 ? null : 'moderation', this.query);
      this.comments = commentsList.data.results;
      this.commentsCount = commentsList.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }

  showUnblockModal(comment: Comment.Comment) {
    this.selectedComment = comment;
    this.isUnblockModalShown = true;
  }
  closeUnblockModal() {
    this.selectedComment = null;
    this.isUnblockModalShown = false;
    this.unbanForm.resetForm();
  }

  async accept() {
    this.unbanForm.isLoading = true;
    try {
      const resp = await acceptComment(this.selectedComment.id);
      let comment = this.comments.find(it => it.id === this.selectedComment.id);
      comment.state = Comment.CommentState.ok;
      this.closeUnblockModal();
    } catch (error) {
      console.log(error)
    } finally {
      this.unbanForm.isLoading = false;
    }
  }

  showReportModal(comment: Comment.Comment) {
    this.selectedComment = comment;
    this.isReportModalShown = true;
  }
  closeReportModal() {
    this.selectedComment = null;
    this.isReportModalShown = false;
    this.banForm.resetForm();
  }

  showRemoveModal(comment: Comment.Comment) {
    this.selectedComment = comment;
    this.isRemoveModalShown = true;
  }
  closeRemoveModal() {
    this.selectedComment = null;
    this.isRemoveModalShown = false;
  }

  async remove() {
    this.removeForm.isLoading = true;
    try {
      await removeComment(this.selectedComment.id);
      this.comments.find((it, i) => {
        if (it.id === this.selectedComment.id) {
          this.comments.splice(i, 1);
          return true
        }
        return false
      })
      this.closeRemoveModal();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.removeForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.removeForm.isLoading = false;
    }
  }
  
  async report() {
    this.banForm.isLoading = true;
    this.banForm.resetErrors();
    try {
      if (!this.banForm.isValid()) return;

      const resp = await banComment(
        this.selectedComment.id,
        this.banForm.ban_reason,
        this.banForm.ban_reason === Common.BanReason.OTHER ? this.banForm.ban_details.value : undefined);
      let comment = this.comments.find(it => it.id === this.selectedComment.id);
      comment.state = Comment.CommentState.banned;
      this.closeReportModal();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (it === 'ban_reason') this.banForm.ban_reasonError = errorHandler(it, errResponse[it][0]);
        else if (this.banForm[it]) this.banForm[it].error = errorHandler(it, errResponse[it][0]);
        else this.banForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.banForm.isLoading = false;
    }
  }

  async loadMore() {
    this.isLoadingMore = true;
    try {
      const commentsList = await getCommentsList(this.comments.length, 10, this.query.length > 0 ? null : 'moderation', this.query);
      this.comments.push(...commentsList.data.results);
      this.commentsCount = commentsList.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoadingMore = false;
    }
  }

  async mounted() {
    const q = this.$route.query && this.$route.query.q ? String(this.$route.query.q) : '';
    this.query = q;
    this.isPageLoading = true;
    try {
      const commentsList = await getCommentsList(0, 10, q.length > 0 ? null : 'moderation', q);
      this.comments = commentsList.data.results;
      this.commentsCount = commentsList.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
