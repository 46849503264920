
import { Component, Vue, Prop } from 'vue-property-decorator';
import Calendar from './Calendar.vue';
import WeekText from './WeekText.vue';
import DatepickerInput from './DatepickerInput.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { getComponentRoot } from '../util/componentUtils';
import differenceInDays from 'date-fns/differenceInDays';

@Component({
  components: { 
    Calendar,
    WeekText,
    DatepickerInput,
    ButtonComponent
  }
})
export default class CalendarDialogComponent extends Vue {
  @Prop({ type: Date }) minDate: Date;
  @Prop({ type: Date }) maxDate: Date;
  @Prop({ type: Date }) selectedDate: Date;
  @Prop({ type: Date }) inDate: Date;
  @Prop({ type: Date }) outDate: Date;
  @Prop({ type: Array }) stringDates: any[];

  componentRoot = getComponentRoot(this);

  incrCheckin(isNextDate: boolean) {this.componentRoot.$emit('incrCheckin', isNextDate, false)}
  incrCheckout(isNextDate: boolean) {this.componentRoot.$emit('incrCheckout', isNextDate, false)}
  
  isOk() { this.componentRoot.$emit('on-this-day', this.selectedDate); }
  clearDates() { this.componentRoot.$emit('clear-dates'); }
  setLimit(limit: string) { this.componentRoot.$emit('set-limit', limit); }
}
