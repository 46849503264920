
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getComponentRoot } from '../util/componentUtils';
import isToday from 'date-fns/isToday';
import isSameDay from 'date-fns/isSameDay';

@Component({})
export default class DayButtonComponent extends Vue {
  @Prop({ type: Date }) inDate: Date;
  @Prop({ type: Date }) outDate: Date;
  @Prop({ type: Date }) day: Date;
  @Prop({ type: Boolean }) disabled: boolean;
  @Prop({ type: Date }) selected: Date;
  
  componentRoot = getComponentRoot(this);
  hovered: boolean = false;

  toDate(day) { return day.getDate(); }
  hoverIn() { if (!this.disabled) { this.hovered = true; } }
  hoverOut() { if (!this.disabled) { this.hovered = false; } }
  select() { this.componentRoot.$emit('updateSelected', this.day); }

  get isNotEmpty() { return !!this.day; }
  get isSelected() { return this.day ? (this.isInDate || this.isOutDate) : false; }
  get isInDate() { return this.day && this.inDate && isSameDay(this.day, this.inDate); }
  get isOutDate() { return this.day && this.outDate && isSameDay(this.day, this.outDate); }
  get isToday() { return this.day && isToday(this.day); }
  
  get dayStyle() {
    const style = (this.isToday && this.isSelected) ? {
      'calendar-day-text': true,
      'calendar-today-text': false,
      'calendar-weekdays': !this.isToday && !this.isSelected,
      'calendar-day-selected': this.isSelected,
      'calendar-day-selected-text': this.isSelected,
    } : {
      'calendar-day-text': true,
      'calendar-today-text': this.isToday,
      'calendar-weekdays': !this.isToday && !this.isSelected,
      'calendar-day-selected': this.isSelected,
      'calendar-day-selected-text': this.isSelected,
    };

    if (this.hovered && !this.isSelected) {
      if (this.isToday) {
        style['calendar-today-text'] = !style['calendar-today-text'];
      } else {
        style['calendar-weekdays'] = !style['calendar-weekdays'];
      }
      style['calendar-day-selected-text'] = !style['calendar-day-selected-text'];
    }

    return style;
  }
  get hoverStyle() {
    const style = (this.isSelected) ? {
      'calendar-day-bg': true,
      'calendar-day-bg-selected': true,
      'calendar-day-hover': false,
    } : {
      'calendar-day-bg': true,
      'calendar-day-bg-selected': false,
      'calendar-day-hover': false,
    };

    if (!this.isSelected) {
      style['calendar-day-hover'] = this.hovered;
    }

    return style;
  }
  get buttonStyle() {
    return {
      'calendar-day-button': !this.disabled,
      'calendar-day-button-disabled': this.disabled,
      'calendar-checkin-day': this.isInDate && this.outDate,
      'calendar-checkout-day': this.isOutDate && this.inDate
    };
  }
}
