
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { User, UI, Notification } from '@/types';
const user = namespace('user');
import { errorHandler } from '@/utils';
import debounce from 'lodash/debounce';
import { getNotificationsList, createNotification } from '@/api/services/notification';
import { getUserByID } from '@/api/services/users';
import SearchComponent from '@/components/SearchComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import RadioComponent from '@/components/RadioComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import DropdownComponent from '@/components/DropdownComponent.vue';
import TextareaComponent from '@/components/TextareaComponent.vue';
import UserSelectMultipleDropdownComponent from '@/components/UserSelectMultipleDropdownComponent.vue';

@Component({
  metaInfo: {
    title: 'Уведомления'
  },
  components: {
    SearchComponent,
    ButtonComponent,
    ModalComponent,
    RadioComponent,
    SpinnerComponent,
    DropdownComponent,
    TextareaComponent,
    UserSelectMultipleDropdownComponent
  }
})
export default class NotificationsPage extends Vue {
  @user.State user: User.Profile;

  isPageLoading: boolean = true;
  query: string = this.$route.query && this.$route.query.q ? String(this.$route.query.q) : '';
  isLoadingMore: boolean = false;
  isAddModalShown: boolean = false;
  isViewModalShown: boolean = false;
  notificationsList: Notification.NotificationItem[] = [];
  notificationsCount: number = 0;
  createNotificationForm: Notification.CreateNotificationForm = new Notification.CreateNotificationForm();
  selectedNotification: Notification.NotificationItem = null;
  groups: UI.DropdownItem[] = [
    { value: 'all', name: 'Всем пользователям' },
    { value: 'server_owners', name: 'Всем владельцам серверов' },
    { value: 'custom', name: 'Выбрать пользователей' }
  ];
  types: UI.DropdownItem[] = [
    { value: 'project_promotion', name: 'Раскрутка сервера' },
    { value: 'server_moderation', name: 'Проверка сервера' },
    { value: 'project_comments', name: 'Отзывы' },
    { value: 'project_first_comments', name: 'Первый отзыв' },
    { value: 'max_online', name: 'Новый рекорд!' },
    { value: 'max_rank', name: 'Ближе к топу' },
    { value: 'violation', name: 'Нарушение' },
    { value: 'notification', name: 'Уведомление' },
    { value: 'greeting', name: 'Здравствуйте!' }
  ];
  onQueryChangeDebounced: Function = debounce(this.onQueryChange, 350);

  @Watch('query') queryWatcher(value: string) {
    this.isPageLoading = true;
    this.onQueryChangeDebounced();
  }

  async onQueryChange() {
    try {
      this.$router.push({ query: this.query ? { q: this.query } : null })
      const resp = await getNotificationsList(0, 10);
      this.notificationsList = resp.data.results;
      this.notificationsCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }

  get selectedNotificationRecipients() {
    if (this.selectedNotification === null) return '';
    const type = this.groups.find(it => it.value === this.selectedNotification.group);
    if (type && type.value != "custom") return type.name;
    else if (type && type.value === "custom" && this.selectedNotification.users && this.selectedNotification.users.length > 0) {
      return this.selectedNotification.users_models.map(it => it.username).join(', ');
    } else return ''
    
  }
  getNotificationType(type: string) {
    const _type = this.types.find(it => it.value === type);
    return _type ? _type.name : 'Уведомление'
  }

  async openViewModal(notif: Notification.NotificationItem) {
    let users_models = [];
    if (notif.group && notif.group === "custom" && notif.users && notif.users.length > 0) {
      await Promise.all([...notif.users.map(it => getUserByID(it))])
        .then(res => { users_models = res.map(x => x.data); })
    }
    this.selectedNotification = notif;
    this.selectedNotification.users_models = users_models;
    this.$nextTick(() => {
      this.isViewModalShown = true;
    })
  }

  closeModals() {
    this.isAddModalShown = false;
    this.isViewModalShown = false;
    this.$nextTick(() => {
      this.selectedNotification = null;
      this.createNotificationForm = new Notification.CreateNotificationForm();
    })
  }

  async create() {
    this.createNotificationForm.isLoading = true;
    try {
      if (!this.createNotificationForm.isValid()) return;

      const users = this.createNotificationForm.group === Notification.NotificationGroup.custom ? this.createNotificationForm.users.map(it => it.id) : undefined;
      const resp = await createNotification(this.createNotificationForm.group,
                                            this.createNotificationForm.type.value,
                                            this.createNotificationForm.text.value,
                                            users);
      // this.notificationsList.unshift(resp.data)
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (it === 'group') this.createNotificationForm.group_error= errorHandler(it, errResponse[it][0]);
        else if (it === 'type') this.createNotificationForm.type_error= errorHandler(it, errResponse[it][0]);
        else if (it === 'users') this.createNotificationForm.users_error= errorHandler(it, errResponse[it][0]);
        else if (this.createNotificationForm[it]) this.createNotificationForm[it].error = errorHandler(it, errResponse[it][0]);
        else this.createNotificationForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.createNotificationForm.isLoading = false;
    }
  }

  async loadMore() {
    this.isLoadingMore = true;
    try {
      const resp = await getNotificationsList(this.notificationsList.length, 10);
      this.notificationsList.push(...resp.data.results);
      this.notificationsCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoadingMore = false;
    }
  }

  async mounted() {
    const q = this.$route.query && this.$route.query.q ? String(this.$route.query.q) : '';
    this.query = q;
    this.isPageLoading = true;
    try {
      const resp = await getNotificationsList(0, 10);
      this.notificationsList = resp.data.results;
      this.notificationsCount = resp.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
