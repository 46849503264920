
import { Vue, Component, Prop } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';

@Component({
  components: {
    ButtonComponent
  }
})
export default class ShowMoreComponent extends Vue {
  @Prop({ type: String, default: 'Показать все' }) btnShow: string;
  @Prop({ type: String, default: 'Скрыть' }) btnHide: string;
  @Prop({ type: Number, default: 0 }) minHeight: number;

  isOpen: boolean = false;
  isBtnNeeded: boolean = false;

  toggle() {
    const content = (this.$refs.showMore as HTMLElement);
    if (this.isOpen) content.style.maxHeight = `${this.minHeight}px`;
    else content.style.maxHeight = `${content.scrollHeight}px`;

    this.isOpen = !this.isOpen;
  }

  mounted() {
    this.isBtnNeeded = this.$refs.showMore && ((this.$refs.showMore as HTMLElement).scrollHeight) > this.minHeight;
  }
}
