
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Project, Common, UI } from '@/types';
import { getProjects, moderateProject } from '@/api/services/servers';
import { errorHandler } from '@/utils';
import debounce from 'lodash/debounce';
import addDays from 'date-fns/addDays';
import SearchComponent from '@/components/SearchComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ReportModalComponent from '@/components/ReportModalComponent.vue';
import TextModalComponent from '@/components/TextModalComponent.vue';
import DropdownComponent from '@/components/DropdownComponent.vue';

@Component({
  metaInfo: {
    title: 'Модерация проектов'
  },
  components: {
    SearchComponent,
    SpinnerComponent,
    ButtonComponent,
    ReportModalComponent,
    TextModalComponent,
    DropdownComponent
  }
})
export default class ProjectsPage extends Vue {
  isPageLoading: boolean = true;
  query: string = this.$route.query && this.$route.query.q ? String(this.$route.query.q) : '';
  statesList: any[] = [
    { name: 'Все', value: null },
    { name: 'Забанен', value: Project.ProjectState.BANNED },
    { name: 'Новый проект', value: Project.ProjectState.CREATED },
    { name: 'На модерации', value: Project.ProjectState.MODERATION },
    { name: 'Отклонен', value: Project.ProjectState.DECLINED },
    { name: 'Одобрен', value: Project.ProjectState.OK }
  ];
  state: any = this.$route.query && this.$route.query.st ? this.statesList.find(it => it.value === this.$route.query.st) : this.statesList[0];
  projects: Project.Project[] = [];
  projectsCount: number = 0;
  isBanModalShown: boolean = false;
  isPenaltyModalShown: boolean = false;
  isUnBanModalShown: boolean = false;
  banForm: Common.BanForm = new Common.BanForm;
  penaltyForm: Project.PenaltyForm = new Project.PenaltyForm;
  unbanForm: UI.Form = new UI.Form;
  selectedProject: Project.Project = null;
  isLoadingMore: boolean = false;
  baseURL: string = process.env.VUE_APP_FRONT_URL;

  penalizePointsValues = new Project.PenalizePointsValues().getAsArray();
  penalizeExpireValues = new Project.PenalizeExpireValues().getAsArray();
  reasonsCustom: Common.BanReason[] = [
    Common.BanReason.WRONG_CATEGORIES,
    Common.BanReason.FRAUD,
    Common.BanReason.SPAM,
    Common.BanReason.OTHER
  ]

  onQueryChangeDebounced: Function = debounce(this.onQueryChange, 350);

  @Watch('query') queryWatcher(value: string) {
    this.isPageLoading = true;
    this.onQueryChangeDebounced();
  }

  @Watch('state') stateWatcher(value: Project.ProjectState) {
    this.isPageLoading = true;
    this.onQueryChange();
  }

  async onQueryChange() {
    try {
      this.$router.push({ query: { q: this.query ? this.query : undefined, st: this.state && this.state.value ? this.state.value : undefined } })
      const projectsList = await getProjects(0, 10, this.query, this.state.value);
      this.projects = projectsList.data.results;
      this.projectsCount = projectsList.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }

  showBanModal(project: Project.Project) {
    this.selectedProject = project;
    this.banForm = new Common.BanForm();
    this.isBanModalShown = true;
  }
  showPenaltyModal(project: Project.Project) {
    this.selectedProject = project;
    this.penaltyForm = new Project.PenaltyForm();
    this.isPenaltyModalShown = true;
  }
  showUnbanModal(project: Project.Project) {
    this.selectedProject = project;
    this.unbanForm = new UI.Form();
    this.isUnBanModalShown = true;
  }
  closeModals() {
    this.isBanModalShown = false;
    this.isPenaltyModalShown = false;
    this.isUnBanModalShown = false;
    
    this.$nextTick(() => {
      this.selectedProject = null;
    })
  }

  async unban() {
    this.unbanForm.isLoading = true;
    try {
      const resp = await moderateProject(
        this.selectedProject.id,
        this.selectedProject.state === Project.ProjectState.BANNED ? Project.ModerationAction.UNBAN : Project.ModerationAction.APPROVE
      );
      const index = this.projects.findIndex(it => it.id === this.selectedProject.id);
      if (index >= 0) this.projects[index] = resp.data;
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.unbanForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.unbanForm.isLoading = true;
    }
  }

  async ban() {
    this.banForm.isLoading = true;
    this.banForm.resetErrors();
    try {
      if (!this.banForm.isValid()) return;

      const resp = await moderateProject(
        this.selectedProject.id,
        this.selectedProject.state === Project.ProjectState.OK ? Project.ModerationAction.BAN : Project.ModerationAction.DECLINE,
        this.banForm.ban_reason,
        this.banForm.ban_reason === Common.BanReason.OTHER ? this.banForm.ban_details.value : undefined);
      const index = this.projects.findIndex(it => it.id === this.selectedProject.id);
      if (index >= 0) this.projects[index] = resp.data;
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (it === 'reason') this.banForm.ban_reasonError = errorHandler(it, errResponse[it][0]);
        else if (it === 'details') this.banForm.ban_details.error = errorHandler(it, errResponse[it][0]);
        else if (this.banForm[it]) this.banForm[it].error = errorHandler(it, errResponse[it][0]);
        else this.banForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.banForm.isLoading = false;
    }
  }

  async setPenalty() {
    this.penaltyForm.isLoading = true;
    this.penaltyForm.resetErrors();
    try {
      if (!this.penaltyForm.isValid()) return;

      const expires = addDays(new Date(), this.penaltyForm.penalty_expires.value);
      const resp = await moderateProject(
        this.selectedProject.id,
        Project.ModerationAction.PENALIZE,
        this.penaltyForm.ban_reason,
        this.penaltyForm.ban_reason === Common.BanReason.OTHER ? this.banForm.ban_details.value : undefined,
        this.penaltyForm.penalty_points.value,
        expires);
      const index = this.projects.findIndex(it => it.id === this.selectedProject.id);
      if (index >= 0) this.projects[index] = resp.data;
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (it === 'reason') this.penaltyForm.ban_reasonError = errorHandler(it, errResponse[it][0]);
        else if (it === 'details') this.penaltyForm.ban_details.error = errorHandler(it, errResponse[it][0]);
        else if (it === 'penalty_points') this.penaltyForm.penalty_pointsError = errorHandler(it, errResponse[it][0]);
        else if (it === 'penalty_expires') this.penaltyForm.penalty_expiresError = errorHandler(it, errResponse[it][0]);
        else if (this.penaltyForm[it]) this.penaltyForm[it].error = errorHandler(it, errResponse[it][0]);
        else this.penaltyForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.penaltyForm.isLoading = false;
    }
  }

  async loadMore() {
    this.isLoadingMore = true;
    try {
      const projectsList = await getProjects(this.projects.length, 10, this.query, this.state.value);
      this.projects.push(...projectsList.data.results);
      this.projectsCount = projectsList.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoadingMore = false;
    }
  }

  projectOnline (servers: Project.Server[]) {
    const sum = servers.reduce((acc: number, item: Project.Server) => {
      acc += item.online
      return acc
    }, 0)

    return sum > 0 ? sum : -1
  }

  projectOnlinePercent (servers: Project.Server[]) {
    const serversLength = servers.length
    if (serversLength) {
      const uptime = servers.reduce((acc: number, item: Project.Server) => {
        acc += item.recent_uptime
        return acc
      }, 0)

      return parseInt(((uptime / serversLength / 1) * 100).toString())
    }
    return 0
  }
  
  async mounted() {
    this.isPageLoading = true;
    try {
      const projectsList = await getProjects(0, 10, this.query, this.state.value);
      this.projects = projectsList.data.results;
      this.projectsCount = projectsList.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
