import { Edits } from '@/types';
import { jsonClient } from '../config';

export function getEditsList(offset?: number, limit?: number, search?: string, reviewed?: boolean) {
  return jsonClient.get<Edits.EditsResponse>('/edits/', { params: { offset, limit, search, reviewed } })
}

export function getEditByID(id: string | number) {
  return jsonClient.get<any>(`/edits/${id}`)
}

export function reviewEdit(id: string | number) {
  return jsonClient.post<any>(`/edits/${id}/review/`)
}

export function getStats(start: string, end: string, offset?: number, limit?: number) {
  return jsonClient.get<Edits.StatsResponse>(`/admin/stats/get/`, { params: { start, end, offset, limit } })
}