
import { Component, Vue, Watch } from 'vue-property-decorator';
import { User, Common, UI } from '@/types';
import { getUsersList, moderateUser } from '@/api/services/users';
import debounce from 'lodash/debounce';
import { errorHandler } from '@/utils';
import SearchComponent from '@/components/SearchComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ReportModalComponent from '@/components/ReportModalComponent.vue';
import TextModalComponent from '@/components/TextModalComponent.vue';

@Component({
  metaInfo: {
    title: 'Модерация пользователей'
  },
  components: {
    SearchComponent,
    SpinnerComponent,
    ButtonComponent,
    ReportModalComponent,
    TextModalComponent
  }
})
export default class UsersPage extends Vue {
  isPageLoading: boolean = true;
  query: string = '';
  users: User.Profile[] = [];
  usersCount: number = 0;
  isBanModalShown: boolean = false;
  isUnbanModalShown: boolean = false;
  banForm: Common.BanForm = new Common.BanForm;
  unbanForm: UI.Form = new UI.Form;
  selectedUserID: number = null;
  isLoadingMore: boolean = false;
  onQueryChangeDebounced: Function = debounce(this.onQueryChange, 350);

  reasonsCustom: Common.BanReason[] = [
    Common.BanReason.OBSCENE,
    Common.BanReason.FRAUD,
    Common.BanReason.SPAM,
    Common.BanReason.OTHER
  ]

  @Watch('query') queryWatcher(value: string) {
    this.isPageLoading = true;
    this.onQueryChangeDebounced();
  }

  async onQueryChange() {
    try {
      this.$router.push({ query: this.query ? { q: this.query } : null })
      const usersList = await getUsersList(0, 10, this.query);
      this.users = usersList.data.results;
      this.usersCount = usersList.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }

  showBanModal(userID: number) {
    this.selectedUserID = userID;
    this.$nextTick(() => {
      this.isBanModalShown = true;
    })
  }
  showUnbanModal(userID: number) {
    this.selectedUserID = userID;
    this.$nextTick(() => {
      this.isUnbanModalShown = true;
    })
  }

  async ban() {
    this.banForm.isLoading = true;
    this.banForm.resetErrors();
    try {
      if (!this.banForm.isValid()) return;
      const resp = await moderateUser(
        this.selectedUserID,
        User.UserModerationAction.BAN,
        this.banForm.ban_reason,
        this.banForm.ban_reason === Common.BanReason.OTHER ? this.banForm.ban_details.value : undefined);
      const index = this.users.findIndex(it => it.id === this.selectedUserID);
      if (index >= 0) this.users[index] = resp.data;
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        if (it === 'reason') this.banForm.ban_reasonError = errorHandler(it, errResponse[it][0]);
        else if (it === 'details') this.banForm.ban_details.error = errorHandler(it, errResponse[it][0]);
        else if (this.banForm[it]) this.banForm[it].error = errorHandler(it, errResponse[it][0]);
        else this.banForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.banForm.isLoading = false;
    }
  }

  async unban() {
    this.unbanForm.isLoading = true;
    try {
      const resp = await moderateUser(this.selectedUserID, User.UserModerationAction.UNBAN);
      const index = this.users.findIndex(it => it.id === this.selectedUserID);
      if (index >= 0) this.users[index] = resp.data;
      this.closeModals();
    } catch (error) {
      const errResponse = error && error.data ? error.data : {};
      Object.keys(errResponse).forEach(it => {
        this.unbanForm.error = errorHandler(it, errResponse[it][0])
      })
    } finally {
      this.unbanForm.isLoading = false;
    }
  }

  closeModals() {
    this.isBanModalShown = false;
    this.isUnbanModalShown = false;
    
    this.$nextTick(() => {
      this.selectedUserID = null;
    })
  }

  async loadMore() {
    this.isLoadingMore = true;
    try {
      const usersList = await getUsersList(this.users.length, 10, this.query);
      this.users.push(...usersList.data.results);
      this.usersCount = usersList.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoadingMore = false;
    }
  }

  async mounted() {
    const q = this.$route.query && this.$route.query.q ? String(this.$route.query.q) : '';
    this.query = q;
    this.isPageLoading = true;
    try {
      const usersList = await getUsersList(0, 10, q);
      this.users = usersList.data.results;
      this.usersCount = usersList.data.count;
    } catch (error) {
      console.log(error)
    } finally {
      this.isPageLoading = false;
    }
  }
}
